import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { calculateDeliveryCost } from "../DeliveryCost/DeliveryCostCalculations";
import { projectFirestore } from "../../../firebase/config";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";

import { Form, Button, Container, Row, Col } from "react-bootstrap";

const DeliveryForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useContext(UserContext);
  const { selectedPickupAddress, selectedDeliveryAddress } =
    location.state || {};
  const [categories, setCategories] = useState(
    location.state?.categories || []
  );
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [packageDetails, setPackageDetails] = useState({
    weight: "",
    dimensions: { length: "", width: "", height: "" },
    description: "",
    pickupDate: new Date(),
    deliveryDate: new Date(),
    attributes: {},
  });
  const [deliveryCost, setDeliveryCost] = useState(0);

  useEffect(() => {
    const firestore = getFirestore();
    if (!categories.length) {
      const fetchCategories = async () => {
        try {
          const querySnapshot = await getDocs(
            collection(firestore, "DeliveryCategories")
          );
          const fetchedCategories = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCategories(fetchedCategories);
        } catch (error) {
          console.error("Error fetching categories:", error);
        }
      };
      fetchCategories();
    }
  }, [categories]);

  
  const calculateCost = async () => {
    // Log the current state for debugging purposes
    console.log("Calculating cost with:", {
      selectedPickupAddress,
      selectedDeliveryAddress,
      packageDetails,
    });

    // Check if all required fields are not only present but also valid
    if (
      !selectedPickupAddress ||
      !selectedDeliveryAddress ||
      !packageDetails.weight ||
      packageDetails.dimensions.length === "" ||
      packageDetails.dimensions.width === "" ||
      packageDetails.dimensions.height === "" ||
      isNaN(parseFloat(packageDetails.dimensions.length)) ||
      isNaN(parseFloat(packageDetails.dimensions.width)) ||
      isNaN(parseFloat(packageDetails.dimensions.height))
    ) {
      console.log("Waiting for complete package details");
      return;
    }

    // If all inputs are valid, parse them to ensure numeric values
    const dimensions = {
      length: parseFloat(packageDetails.dimensions.length),
      width: parseFloat(packageDetails.dimensions.width),
      height: parseFloat(packageDetails.dimensions.height),
    };

    // Check again after parsing if any dimension or weight is NaN
    if (
      isNaN(packageDetails.weight) ||
      isNaN(dimensions.length) ||
      isNaN(dimensions.width) ||
      isNaN(dimensions.height)
    ) {
      console.log("Invalid package details for delivery cost calculation.");
      return;
    }

    try {
      // Assuming calculateDeliveryCost is correctly imported and utilized
      const cost = await calculateDeliveryCost(
        packageDetails,
        "STANDARD",
        selectedPickupAddress,
        selectedDeliveryAddress
      );
      setDeliveryCost(isNaN(cost) ? 0 : cost);
    } catch (error) {
      console.error("Error calculating delivery cost:", error);
    }
  };

  // Ensure this function is called under the right conditions
  useEffect(() => {
    if (
      selectedPickupAddress &&
      selectedDeliveryAddress &&
      packageDetails.weight &&
      packageDetails.dimensions.length &&
      packageDetails.dimensions.width &&
      packageDetails.dimensions.height
    ) {
      calculateCost();
    }
  }, [selectedPickupAddress, selectedDeliveryAddress, packageDetails]);


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPackageDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleDimensionChange = (dimension, value) => {
    setPackageDetails((prevDetails) => ({
      ...prevDetails,
      dimensions: { ...prevDetails.dimensions, [dimension]: value },
    }));
  };

  const handleAttributeChange = (
    attributeKey,
    option,
    isChecked,
    isSubCategory
  ) => {
    setPackageDetails((prevDetails) => {
      const updatedAttributes = prevDetails.attributes[attributeKey]
        ? [...prevDetails.attributes[attributeKey]]
        : [];
      if (isChecked) {
        updatedAttributes.push(option);
      } else {
        const index = updatedAttributes.indexOf(option);
        if (index > -1) {
          updatedAttributes.splice(index, 1);
        }
      }
      return {
        ...prevDetails,
        attributes: {
          ...prevDetails.attributes,
          [attributeKey]: updatedAttributes,
        },
      };
    });
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedSubCategory("");
    setPackageDetails({ ...packageDetails, attributes: {} });
  };

  const handleSubCategoryChange = (e) => {
    setSelectedSubCategory(e.target.value);
    setPackageDetails({ ...packageDetails, attributes: {} });
  };

  const renderAttributesInput = (attributes, isSubCategory = false) => {
    return Object.entries(attributes).map(([key, options], index) => {
      const optionsArray = options.split(", ");

      return (
        <div key={index}>
          <label>{key}:</label>
          {optionsArray.map((option) => (
            <div key={option}>
              <Form.Check
                type="checkbox"
                id={
                  isSubCategory
                    ? `sub-${key}-${option}`
                    : `cat-${key}-${option}`
                }
                label={option}
                checked={
                  packageDetails.attributes[key]?.includes(option) || false
                }
                onChange={(e) =>
                  handleAttributeChange(
                    key,
                    option,
                    e.target.checked,
                    isSubCategory
                  )
                }
              />
            </div>
          ))}
        </div>
      );
    });
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   if (!currentUser) {
  //     console.error("No user logged in.");
  //     return;
  //   }

  //   const orderData = {
  //     userId: currentUser.uid,
  //     selectedPickupAddress,
  //     selectedDeliveryAddress,
  //     packageDetails: {
  //       selectedCategory,
  //       selectedSubCategory,
  //       ...packageDetails,
  //       pickupDate: packageDetails.pickupDate.toISOString(),
  //       deliveryDate: packageDetails.deliveryDate.toISOString(),
  //     },
  //     deliveryCost,
  //     orderStatus: "pending",
  //     paymentStatus: "unpaid",
  //     isPaymentCompleted: false,
  //     orderDate: new Date().toISOString(),
  //   };

  //   try {
  //     const docRef = await addDoc(
  //       collection(projectFirestore, "deliveryorders"),
  //       orderData
  //     );
  //     const newOrderId = docRef.id;
  //     await updateDoc(doc(projectFirestore, "deliveryorders", newOrderId), {
  //       orderId: newOrderId,
  //     });
  //     navigate("/delivery-order-summary", {
  //       state: { orderData: { ...orderData, orderId: newOrderId } },
  //     });
  //   } catch (error) {
  //     console.error("Error submitting order:", error);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!currentUser) {
      console.error("No user logged in.");
      return;
    }

    const orderData = {
      userId: currentUser.uid,
      selectedPickupAddress,
      selectedDeliveryAddress,
      packageDetails: {
        selectedCategory,
        selectedSubCategory,
        ...packageDetails,
        pickupDate: packageDetails.pickupDate.toISOString(),
        deliveryDate: packageDetails.deliveryDate.toISOString(),
      },
      deliveryCost,
      orderStatus: "pending",
      paymentStatus: "unpaid",
      isPaymentCompleted: false,
      orderDate: new Date().toISOString(),
    };

    // Log the order data before submission
    console.log("Submitting Order Data:", JSON.stringify(orderData, null, 2));

    try {
      const docRef = await addDoc(
        collection(projectFirestore, "deliveryorders"),
        orderData
      );
      console.log(
        "Order submitted successfully, document reference ID:",
        docRef.id
      );
      navigate("/delivery-order-summary", {
        state: { orderData: { ...orderData, orderId: docRef.id } },
      });
    } catch (error) {
      console.error("Error submitting order:", error);
    }
  };


  const selectedCategoryObj = categories.find(
    (cat) => cat.id === selectedCategory
  );
  const selectedSubCategoryObj = selectedCategoryObj?.subCategories.find(
    (sub) => sub.id === selectedSubCategory
  );

  return (
    <Container>
      <h2 className="mt-4 mb-4">Delivery Form</h2>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
            <p>
              <strong>Pickup Address:</strong>{" "}
              {selectedPickupAddress
                ? `${selectedPickupAddress.fullName} - ${selectedPickupAddress.street}, ${selectedPickupAddress.city}`
                : "Not selected"}
            </p>
            <p>
              <strong>Delivery Address:</strong>{" "}
              {selectedDeliveryAddress
                ? `${selectedDeliveryAddress.fullName} - ${selectedDeliveryAddress.street}, ${selectedDeliveryAddress.city}`
                : "Not selected"}
            </p>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>Category:</Form.Label>
            <Form.Select
              value={selectedCategory}
              onChange={handleCategoryChange}
            >
              <option value="">Select a Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>

        {selectedCategoryObj &&
          renderAttributesInput(selectedCategoryObj.attributes)}

        <Row className="mb-3">
          <Col>
            <Form.Label>Sub-Category:</Form.Label>
            <Form.Select
              value={selectedSubCategory}
              onChange={handleSubCategoryChange}
            >
              <option value="">Select a Sub-Category</option>
              {selectedCategory &&
                categories
                  .find((cat) => cat.id === selectedCategory)
                  ?.subCategories.map((subCat) => (
                    <option key={subCat.id} value={subCat.id}>
                      {subCat.name}
                    </option>
                  ))}
            </Form.Select>
          </Col>
        </Row>

        {selectedSubCategoryObj &&
          renderAttributesInput(selectedSubCategoryObj.attributes, true)}

        <Row className="mb-3">
          <Col>
            <Form.Label>Weight:</Form.Label>
            <Form.Control
              type="number"
              name="weight"
              value={packageDetails.weight}
              onChange={handleInputChange}
              required
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>Dimensions:</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="number"
                name="length"
                value={packageDetails.dimensions.length}
                onChange={(e) =>
                  handleDimensionChange("length", e.target.value)
                }
                className="me-2"
              />
              <Form.Control
                type="number"
                name="width"
                value={packageDetails.dimensions.width}
                onChange={(e) => handleDimensionChange("width", e.target.value)}
                className="me-2"
              />
              <Form.Control
                type="number"
                name="height"
                value={packageDetails.dimensions.height}
                onChange={(e) =>
                  handleDimensionChange("height", e.target.value)
                }
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>Description:</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              value={packageDetails.description}
              onChange={handleInputChange}
              required
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>Pickup Date:</Form.Label>
            <DatePicker
              className="form-control"
              required
              selected={packageDetails.pickupDate}
              onChange={(date) =>
                setPackageDetails({ ...packageDetails, pickupDate: date })
              }
              dateFormat="dd/MM/yyyy"
              minDate={new Date()}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>Delivery Date:</Form.Label>
            <DatePicker
              className="form-control"
              required
              selected={packageDetails.deliveryDate}
              onChange={(date) =>
                setPackageDetails({ ...packageDetails, deliveryDate: date })
              }
              dateFormat="dd/MM/yyyy"
              minDate={new Date()}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <p>Calculated Delivery Cost: {deliveryCost.toFixed(2)}</p>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Button variant="primary" type="submit">
              Next
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default DeliveryForm;
