import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { projectFirestore } from "../../firebase/config";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import FlutterwavePayments from "../Payments/FlutterwavePayments";
import OrderSummary from "./OrderSummary";
import { UserContext } from "../contexts/UserContext";

function OrderIntake() {
  const [services, setServices] = useState([]);
  const [selectedServiceId, setSelectedServiceId] = useState("");
  const [serviceDetails, setServiceDetails] = useState(null);
  const [formData, setFormData] = useState({
    serviceDate: "",
    serviceTime: "",
    quantity: "",
    additionalInfo: "",
    frequency: "",
  });
  const [calculatedPrice, setCalculatedPrice] = useState(0);
  const [step, setStep] = useState(1); // Manage step flow: 1 - Select Service, 2 - Appointment Details, 3 - Review Order, 4 - Payment
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);

  const generateLabel = (serviceDetails) => {
    if (!serviceDetails) return "Loading..."; // Return a loading message if serviceDetails is not loaded yet

    let labelBase = "Enter Number of";
    let serviceName = serviceDetails.name;

    if (serviceName.endsWith("Cleaning")) {
      let trimmedServiceName = serviceName.replace("Cleaning", "").trim();
      return `${labelBase} ${trimmedServiceName} Areas to be Cleaned:`;
    } else if (serviceName.endsWith("Polishing")) {
      let trimmedServiceName = serviceName.replace("Polishing", "").trim();
      return `${labelBase} ${trimmedServiceName} Areas to be Polished:`;
    } else {
      return `${labelBase} ${serviceName} Sessions:`;
    }
  };

  useEffect(() => {
    const fetchServices = async () => {
      const querySnapshot = await getDocs(
        collection(projectFirestore, "cleaningServices")
      );
      const servicesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setServices(servicesList);
    };
    fetchServices();
  }, []);

  useEffect(() => {
    if (!selectedServiceId) return;
    const fetchServiceDetails = async () => {
      const docRef = doc(
        projectFirestore,
        "cleaningServices",
        selectedServiceId
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setServiceDetails({ id: docSnap.id, ...docSnap.data() });
        // Placeholder for setting the calculated price based on service details
        // You'll need to implement this according to your pricing logic
        setCalculatedPrice(calculatePriceForService(docSnap.data()));
      }
    };
    fetchServiceDetails();
  }, [selectedServiceId]);

  // Placeholder function for calculatePrice - Implement your pricing logic here
  const calculatePriceForService = (serviceDetails) => {
    // Example calculation, replace with actual logic
    return serviceDetails.basePrice; // Assume each serviceDetail has a basePrice for simplicity
  };

  const handleServiceChange = (event) => {
    setSelectedServiceId(event.target.value);
  };

  // const handleInputChange = (event) => {
  //     const { name, value } = event.target;
  //     setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  // };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

    // If the quantity changes, update the price immediately (assuming 'quantity' is the name for your input field)
    if (name === "quantity") {
      const newPrice = calculateTotalPrice(serviceDetails.price, value);
      setCalculatedPrice(newPrice);
    }
  };

  // Simplified price calculation based on quantity and base price
  const calculateTotalPrice = (basePrice, quantity) => {
    return basePrice * quantity;
  };

  // Ensure you're calling this function whenever the quantity input changes
  // This could be part of your form submission or real-time price update logic

  const handleSubmit = (event) => {
    event.preventDefault();
    setStep(step + 1); // Proceed to the next step
  };

  const handleConfirm = () => {
    // Proceed to payment
    setStep(4);
  };

  const handleEditOrder = () => {
    // Allow user to go back and edit the order
    setStep(2);
  };

  const handlePaymentSuccess = () => {
    navigate("/success"); // Redirect to a success page after payment
  };

  // JSX for the different steps
  const renderStepContent = () => {
    switch (step) {
      case 1: // Service selection
        return (
          <div className="container p-5">
            <p className="lead text-muted">
              Welcome to our easy and convenient online booking. Please select
              the cleaning service you require, and we'll guide you through a
              few simple steps to secure your appointment.
            </p>
            <h3 className="mb-3">Select a Service</h3>
            <div className="mb-4">
              <select
                className="form-select"
                value={selectedServiceId}
                onChange={handleServiceChange}
              >
                <option value="">Select a service</option>
                {services.map((service) => (
                  <option key={service.id} value={service.id}>
                    {service.name}
                  </option>
                ))}
              </select>
            </div>
            <button className="btn btn-primary" onClick={() => setStep(2)}>
              Next
            </button>
          </div>
        );

      case 2: // Appointment details
        return (
          <div className="container p-5">
            {" "}
            <form onSubmit={handleSubmit} className="mt-3">
              <h2 className="mb-4">Appointment Details</h2>

              <div className="mb-3">
                <label htmlFor="serviceDate" className="form-label">
                  Service Date:
                </label>
                <input
                  type="date"
                  name="serviceDate"
                  id="serviceDate"
                  className="form-control"
                  value={formData.serviceDate}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="serviceTime" className="form-label">
                  Service Time:
                </label>
                <input
                  type="time"
                  name="serviceTime"
                  id="serviceTime"
                  className="form-control"
                  value={formData.serviceTime}
                  onChange={handleInputChange}
                  required
                />
              </div>
              {serviceDetails && (
                <div className="mb-3">
                  <label className="form-label">
                    {generateLabel(serviceDetails)}
                  </label>
                  <input
                    type="number"
                    id="quantity"
                    name="quantity"
                    className="form-control"
                    value={formData.quantity || ""}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              )}

              <div className="mb-3">
                <label htmlFor="additionalInfo" className="form-label">
                  Additional Information:
                </label>
                <textarea
                  name="additionalInfo"
                  id="additionalInfo"
                  className="form-control"
                  placeholder="Any additional details you'd like us to know?"
                  value={formData.additionalInfo}
                  onChange={handleInputChange}
                ></textarea>
              </div>

              <div className="mb-3">
                <label htmlFor="frequency" className="form-label">
                  Cleaning Frequency:
                </label>
                <select
                  name="frequency"
                  id="frequency"
                  className="form-select"
                  value={formData.frequency}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Frequency</option>
                  <option value="Once">Once</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                </select>
              </div>

              <button type="submit" className="btn btn-primary">
                Review Order
              </button>
            </form>
          </div>
        );

      case 3: // Review Order
        return (
          <OrderSummary
            selectedServiceDetails={serviceDetails}
            calculatedPrice={calculatedPrice}
            formData={formData}
            onConfirm={handleConfirm}
            onEdit={handleEditOrder}
          />
        );
      case 4: // Payment
        return (
          <FlutterwavePayments
            calculatedPrice={calculatedPrice}
            selectedServiceDetails={serviceDetails}
            formData={formData}
            onSuccess={handlePaymentSuccess}
          />
        );
      default:
        return null;
    }
  };

  return <div className="booking-container">{renderStepContent()}</div>;
}

export default OrderIntake;
