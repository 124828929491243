
import React, { useState, useContext, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { doc, getDoc, setDoc, addDoc, collection } from 'firebase/firestore';
import { projectFirestore } from '../../firebase/config';
import { Button, Form, Alert } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { toast } from 'react-toastify'; // Assuming react-toastify is installed

const PolicyEditor = () => {
    const [policyName, setPolicyName] = useState('');
    const [policyDescription, setPolicyDescription] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { currentUser } = useContext(UserContext);
    const { policyId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (policyId) {
            fetchPolicy(policyId);
        }
    }, [policyId]);

    const fetchPolicy = async (policyId) => {
        setLoading(true);
        try {
            const docRef = doc(projectFirestore, 'policies', policyId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setPolicyName(docSnap.data().name);
                setPolicyDescription(docSnap.data().description);
            } else {
                console.error('No such document!');
                setError('No such policy found.');
            }
        } catch (err) {
            console.error('Error fetching document:', err);
            setError('Error fetching policy. Please try again later.');
        } finally {
            setLoading(false);
        }
    };


    const savePolicy = async () => {
        if (!currentUser) {
            setError('You must be logged in to save policies.');
            toast.error('You must be logged in to save policies.'); // Notify the user via toast as well
            return;
        }
    
        if (!policyName.trim() || !policyDescription.trim()) {
            setError('Both policy name and description are required.');
            toast.error('Both policy name and description are required.'); // Notify the user via toast as well
            return;
        }
    
        setLoading(true);
        try {
            const payload = { name: policyName, description: policyDescription };
            let savedPolicyId = policyId; // Reuse existing policyId if editing
    
            if (policyId) {
                // Editing existing policy
                const docRef = doc(projectFirestore, 'policies', policyId);
                await setDoc(docRef, payload);
            } else {
                // Creating new policy
                const docRef = await addDoc(collection(projectFirestore, 'policies'), payload);
                savedPolicyId = docRef.id; // Capture new policyId for redirecting to edit this new policy
            }
            
            toast.success('Policy saved successfully.');
            //navigate(`/edit-policy/${savedPolicyId}`); // Redirect to edit the saved/updated policy
            navigate(`/policy-list`); // Redirect to edit the saved/updated policy
        } catch (err) {
            console.error('Error saving policy:', err);
            setError('Error saving policy. Please try again later.');
            toast.error('Error saving policy. Please try again later.'); // Notify the user via toast as well
        } finally {
            setLoading(false);
        }
    };
    

    return (
        
      <div className="mt-5">
        {error && <Alert variant="danger">{error}</Alert>}
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Policy Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter policy name"
              value={policyName}
              onChange={(e) => setPolicyName(e.target.value)}
              isInvalid={!!error && !policyName.trim()}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Policy Description</Form.Label>
            <ReactQuill
              theme="snow"
              value={policyDescription}
              onChange={setPolicyDescription}
            />
            {/* Adding validation feedback for description could be more complex due to ReactQuill's nature */}
          </Form.Group>
          <Button variant="primary" onClick={savePolicy} disabled={loading}>
            Save Policy
          </Button>
        </Form>
      </div>
    );
};

export default PolicyEditor;
