import React, { useState, useEffect } from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import { projectFirestore } from "../../../firebase/config";
import { collection, getDocs } from "firebase/firestore";

const DeliveryDropdown = () => {
  const [categories, setCategories] = useState([]);
  const [selectedDeliveryCategory, setSelectedDeliveryCategory] = useState("");
  const [selectedDeliverySubcategory, setSelectedDeliverySubcategory] = useState("");
  const [selectedDeliveryCategoryAttributes, setSelectedDeliveryCategoryAttributes] = useState({});
  const [selectedDeliverySubCategoryAttributes, setSelectedDeliverySubCategoryAttributes] = useState({});

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(projectFirestore, "DeliveryCategories"));
        const categoriesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          subCategories: (doc.data().subCategories || []).filter(subCat => subCat.active).map(subCat => ({
            ...subCat,
            attributes: subCat.attributes || {},
          })),
        })).filter(cat => cat.active);
        setCategories(categoriesData);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setSelectedDeliveryCategory(categoryId);
    setSelectedDeliverySubcategory("");
    const category = categories.find(cat => cat.id === categoryId) || { attributes: {} };
    setSelectedDeliveryCategoryAttributes(convertAttributesToArray(category.attributes));
    setSelectedDeliverySubCategoryAttributes({});
  };

  const handleSubCategoryChange = (e) => {
    const subcategoryId = e.target.value;
    setSelectedDeliverySubcategory(subcategoryId);
    const category = categories.find(cat => cat.id === selectedDeliveryCategory) || {};
    const subCategory = (category.subCategories || []).find(sub => sub.id === subcategoryId) || { attributes: {} };
    setSelectedDeliverySubCategoryAttributes(convertAttributesToArray(subCategory.attributes));
  };

  const handleAttributeChange = (attributeKey, option, isChecked, isSubCategory) => {
    const updateFunction = isSubCategory ? setSelectedDeliverySubCategoryAttributes : setSelectedDeliveryCategoryAttributes;
    updateFunction(prevAttributes => ({
      ...prevAttributes,
      [attributeKey]: isChecked
        ? [...(prevAttributes[attributeKey] || []), option]
        : (prevAttributes[attributeKey] || []).filter(item => item !== option),
    }));
  };

  const convertAttributesToArray = (attributes) => {
    const result = {};
    for (const key in attributes) {
      result[key] = attributes[key].split(", ").filter(attr => attr);
    }
    return result;
  };

  const renderAttributesInput = (attributes, isSubCategory) => {
    if (!attributes) {
      return null;
    }
    const selectedAttributes = isSubCategory ? selectedDeliverySubCategoryAttributes : selectedDeliveryCategoryAttributes;

    return Object.entries(attributes).map(([key, options], index) => (
      <div key={index}>
        <label>{key}:</label>
        {options.map(option => (
          <div key={option}>
            <Form.Check
              type="checkbox"
              id={isSubCategory ? `sub-${key}-${option}` : `cat-${key}-${option}`}
              label={option}
              checked={selectedAttributes[key] && selectedAttributes[key].includes(option)}
              onChange={e => handleAttributeChange(key, option, e.target.checked, isSubCategory)}
            />
          </div>
        ))}
      </div>
    ));
  };

  return (
    <Container>
      <Row>
        <Col>
          <Form.Label><span className="fw-bold">Select Delivery Category:</span></Form.Label>
          <Form.Select value={selectedDeliveryCategory} onChange={handleCategoryChange}>
            <option value="">Select a Delivery Category</option>
            {categories.map(category => (
              <option key={category.id} value={category.id}>{category.name}</option>
            ))}
          </Form.Select>
        </Col>
      </Row>

      {selectedDeliveryCategory && (
        <>
          <Row>
            <Col>
              <Form.Label><span className="fw-bold">Category Attributes:</span></Form.Label>
              {renderAttributesInput(selectedDeliveryCategoryAttributes, false)}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label><span className="fw-bold">Select Delivery Sub-Category:</span></Form.Label>
              <Form.Select value={selectedDeliverySubcategory} onChange={handleSubCategoryChange}>
                <option value="">Select a Delivery Sub-Category</option>
                {categories.find(cat => cat.id === selectedDeliveryCategory)?.subCategories.map(subCat => (
                  <option key={subCat.id} value={subCat.id}>{subCat.name}</option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          {selectedDeliverySubcategory && (
            <Row>
              <Col>
                <Form.Label><span className="fw-bold">Subcategory Attributes:</span></Form.Label>
                {renderAttributesInput(selectedDeliverySubCategoryAttributes, true)}
              </Col>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

export default DeliveryDropdown;