import React, { useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
  Tabs,
  Tab,
} from "react-bootstrap";
import DeliveryDropdown from "./DeliveryDropdown"; // Assuming this is the path to your component
import TransportDropdown from "../../SwifttiDeliveries/TransportTypes/TransportDropdown";
import fetchGeolocation from "../../utils/fetchGeolocation";
import TimeSlotSelector from "./TimeSlotSelector";

const PartnerOnboardingForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [partnerType, setPartnerType] = useState("");
  const [operationalModel, setOperationalModel] = useState([]);
  const [name, setName] = useState("");
  const [workers, setWorkers] = useState([]);
  const [errors, setErrors] = useState({});
  const [geolocation, setGeolocation] = useState(null);
  //const [serviceOptions, setServiceOptions] = useState({});
  const [contactInfo, setContactInfo] = useState({
    phone: "",
    email: "",
    address: "",
    streetName: "",
    number: "",
    postCode: "",
    suburb: "",
    city: "",
    longitude: "",
    latitude: "",
  });
  const [businessRegistration, setBusinessRegistration] = useState(null);
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [legalDocuments, setLegalDocuments] = useState({
    driversLicense: null,
    vehicleInsurance: null,
    roadworthinessCertificate: null,
  });
  const [operationalDetails, setOperationalDetails] = useState({
    geographicalCoverage: "",
    availability: "",
    deliveryCapabilities: {},
  });
  const [financialInfo, setFinancialInfo] = useState({
    bankName: "",
    branchName: "",
    accountName: "",
    accountNumber: "",
    momoNumber: "",
    momoProvider: "",
    paymentMethod: "",
  });
  const [performanceMetrics, setPerformanceMetrics] = useState({
    experience: "",
    pastPerformance: "",
  });

  const handleNextStep = () => {
    if (currentStep < 6) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const validateCurrentStep = () => {
    let isValid = true;
    let newErrors = {};

    // Example: Validate name is not empty
    if (currentStep === 1 && !name.trim()) {
      isValid = false;
      newErrors.name = "Name cannot be empty";
    }

    // Example: Validate phone format
    if (currentStep === 1 && !/^\d{10}$/.test(contactInfo.phone)) {
      isValid = false;
      newErrors.phone = "Phone number must be 10 digits";
    }

    setErrors(newErrors);
    return isValid;
  };

  // Vehicle management functions
  const handleVehicleChange = (index, field, value) => {
    const updatedVehicles = [...vehicleDetails];
    updatedVehicles[index] = { ...updatedVehicles[index], [field]: value };
    setVehicleDetails(updatedVehicles);
  };

  const addVehicle = () => {
    setVehicleDetails([...vehicleDetails, { type: "", model: "" }]);
  };

  const removeVehicle = (index) => {
    const updatedVehicles = [...vehicleDetails];
    updatedVehicles.splice(index, 1);
    setVehicleDetails(updatedVehicles);
  };

  // Worker management functions
  const handleWorkerChange = (index, field, value) => {
    const updatedWorkers = [...workers];
    updatedWorkers[index] = { ...updatedWorkers[index], [field]: value };
    setWorkers(updatedWorkers);
  };

  const addWorker = () => {
    setWorkers([...workers, { name: "", contact: "", email: "" }]);
  };

  const removeWorker = (index) => {
    const updatedWorkers = [...workers];
    updatedWorkers.splice(index, 1);
    setWorkers(updatedWorkers);
  };

  const handleOperationalModelChange = (model) => {
    setOperationalModel((prev) => {
      let newModelArray = [...prev];

      if (model === "both") {
        if (newModelArray.includes("both")) {
          // If 'Both' is already selected, deselect it and clear all selections
          newModelArray = [];
        } else {
          // If 'Both' is not selected, select it and both categories
          newModelArray = ["onTheGo", "stationary", "both"];
        }
      } else {
        if (newModelArray.includes("both")) {
          // If 'Both' is selected and user selects either category, deselect 'Both'
          newModelArray = newModelArray.filter((m) => m !== "both");
        }
        // Toggle the individual category
        const index = newModelArray.indexOf(model);
        if (index > -1) {
          newModelArray.splice(index, 1);
        } else {
          newModelArray.push(model);
        }
        // If both individual categories are selected, automatically select 'Both'
        if (
          newModelArray.includes("onTheGo") &&
          newModelArray.includes("stationary") &&
          !newModelArray.includes("both")
        ) {
          newModelArray.push("both");
        }
      }

      return newModelArray;
    });
  };

  const handleFetchGeolocation = () => {
    fetchGeolocation(
      (location) => setGeolocation(location), // Success callback to update geolocation
      (errorMessage) => setErrors(errorMessage) // Error callback to update error
    );
  };

  // const daysOfWeek = [
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  //   "Sunday",
  // ];
  // const initialAvailability = daysOfWeek.reduce((acc, day) => {
  //   acc[day] = { start: "", end: "" }; // Initialize with empty start and end times
  //   return acc;
  // }, {});
  const initialAvailability = {
    Monday: { start: "", end: "" },
    Tuesday: { start: "", end: "" },
    Wednesday: { start: "", end: "" },
    Thursday: { start: "", end: "" },
    Friday: { start: "", end: "" },
    Saturday: { start: "", end: "" },
    Sunday: { start: "", end: "" },
  };

  const [availability, setAvailability] = useState(initialAvailability);


  

  return (
    <Container fluid className="pt-5">
      <Container fluid className="pt-5"></Container>
      <Form>
        <Tabs
          activeKey={currentStep}
          onSelect={(k) => setCurrentStep(Number(k))}
          className="mb-3"
        >
          <Tab eventKey={1} title="General Information">
            <Card>
              <Card.Body>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>
                    Partner Type
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Select
                      aria-label="Partner Type"
                      value={partnerType}
                      onChange={(e) => setPartnerType(e.target.value)}
                    >
                      <option value="">Select Partner Type</option>
                      <option value="soloOperator">Solo Operator</option>
                      <option value="registeredBusiness">
                        Registered Business
                      </option>
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>
                    Operational Model
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Check
                      type="checkbox"
                      label="On-the-Go Delivery Associates"
                      checked={operationalModel.includes("onTheGo")}
                      onChange={() => handleOperationalModelChange("onTheGo")}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Stationary Service Stations"
                      checked={operationalModel.includes("stationary")}
                      onChange={() =>
                        handleOperationalModelChange("stationary")
                      }
                    />
                    <Form.Check
                      type="checkbox"
                      label="Both"
                      checked={operationalModel.includes("both")}
                      onChange={() => handleOperationalModelChange("both")}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={3}>
                    Full Business Name
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Full BusinessName"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>
                    Phone Number
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Phone Number"
                      value={contactInfo.phone}
                      onChange={(e) =>
                        setContactInfo({
                          ...contactInfo,
                          phone: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>
                    Email Address
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="email"
                      placeholder="Email Address"
                      value={contactInfo.email}
                      onChange={(e) =>
                        setContactInfo({
                          ...contactInfo,
                          email: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Street Name
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Street Name"
                      value={contactInfo.streetName}
                      onChange={(e) =>
                        setContactInfo({
                          ...contactInfo,
                          streetName: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    House/Building Number
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Number"
                      value={contactInfo.number}
                      onChange={(e) =>
                        setContactInfo({
                          ...contactInfo,
                          number: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Postal Code
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="PostCode"
                      value={contactInfo.postCode}
                      onChange={(e) =>
                        setContactInfo({
                          ...contactInfo,
                          postCode: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Suburb
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Suburb"
                      value={contactInfo.suburb}
                      onChange={(e) =>
                        setContactInfo({
                          ...contactInfo,
                          suburb: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    City/Town/Village
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="City/Town"
                      value={contactInfo.city}
                      onChange={(e) =>
                        setContactInfo({ ...contactInfo, city: e.target.value })
                      }
                    />
                  </Col>
                </Form.Group>
                {/* Geolocation Button (Trigger fetchGeolocation on click) */}
                <div className="col-12 text-center">
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={handleFetchGeolocation}
                  >
                    Fetch Geolocation
                  </button>
                  {/* Display any errors */}
                  {errors.longitude && (
                    <p className="text-danger">{errors.longitude}</p>
                  )}
                  {errors.latitude && (
                    <p className="text-danger">{errors.latitude}</p>
                  )}
                  {/* Display the fetched geolocation */}
                  {geolocation && (
                    <>
                      <div className="col-12">
                        <label htmlFor="latitude" className="form-label">
                          Latitude:
                        </label>
                        <input
                          type="text"
                          id="latitude"
                          name="latitude"
                          className="form-control"
                          value={geolocation.latitude || ""}
                          onChange={(e) =>
                            setContactInfo({
                              ...contactInfo,
                              latitude: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-12">
                        <label htmlFor="longitude" className="form-label">
                          Longitude:
                        </label>
                        <input
                          type="text"
                          id="longitude"
                          name="longitude"
                          className="form-control"
                          value={geolocation.longitude || ""}
                          onChange={(e) =>
                            setContactInfo({
                              ...contactInfo,
                              longitude: e.target.value,
                            })
                          }
                        />
                      </div>
                    </>
                  )}
                </div>

                {/* Additional fields for geolocation would be similar */}
              </Card.Body>
            </Card>
          </Tab>

          <Tab eventKey={2} title="Legal Compliance">
            <Card>
              <Card.Body>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>
                    Business Certificate
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="file"
                      onChange={(e) =>
                        setLegalDocuments({
                          ...legalDocuments,
                          bizId: e.target.files[0],
                        })
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>
                    Postal & Courier Certification
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="file"
                      onChange={(e) =>
                        setLegalDocuments({
                          ...legalDocuments,
                          regId: e.target.files[0],
                        })
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Tax Identification Number (TIN)
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Tax Identification Number"
                      value={financialInfo.taxId}
                      onChange={(e) =>
                        setFinancialInfo({
                          ...financialInfo,
                          taxId: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>
              </Card.Body>
            </Card>
          </Tab>

          <Tab eventKey={3} title="Operational Details">
            <Card>
              <Card.Body>
                {/* Common Operational Details */}
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>
                    Geographical Coverage
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Specify operating areas"
                      value={operationalDetails.geographicalCoverage}
                      onChange={(e) =>
                        setOperationalDetails({
                          ...operationalDetails,
                          geographicalCoverage: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>
                {/* <Form.Group as={Row}>
                  <Form.Label column sm={3}>
                    Availability
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Hours of operation (e.g., 24/7, 9-5 weekdays)"
                      value={operationalDetails.availability}
                      onChange={(e) =>
                        setOperationalDetails({
                          ...operationalDetails,
                          availability: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group> */}
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>
                    Availability
                  </Form.Label>
                  <Col sm={9}>
                    {/* <Card>
                      <Card.Body>
                        {daysOfWeek.map((day) => (
                          <div key={day}>
                            <h5>{day}</h5>
                            <TimeSlotSelector
                              day={day}
                              availability={availability}
                              setAvailability={setAvailability}
                            />
                          </div>
                        ))}
                      </Card.Body>
                    </Card> */}
                    <Card>
                      <Card.Body>
                        {Object.keys(availability).map((day) => (
                          <div key={day}>
                            <TimeSlotSelector
                              day={day}
                              availability={availability}
                              setAvailability={setAvailability}
                            />
                          </div>
                        ))}
                      </Card.Body>
                    </Card>
                  </Col>
                </Form.Group>

                {/* Conditional Rendering Based on Operational Model */}
                {operationalModel.includes("onTheGo") && (
                  <>
                    {/* Specific for On-the-Go Delivery Associates */}
                    <DeliveryDropdown
                      selectedDeliveryCategory={
                        operationalDetails.selectedDeliveryCategory
                      }
                      setSelectedDeliveryCategory={(value) =>
                        setOperationalDetails({
                          ...operationalDetails,
                          selectedDeliveryCategory: value,
                        })
                      }
                      // Additional props for subcategories and attributes
                    />
                  </>
                )}
                {operationalModel.includes("stationary") && (
                  <>
                    {/* Specific for Stationary Service Stations */}
                    <Form.Group as={Row}>
                      <Form.Label column sm={3}>
                        Storage Capacity
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          type="text"
                          placeholder="Capacity to store packages"
                          value={operationalDetails.storageCapacity}
                          onChange={(e) =>
                            setOperationalDetails({
                              ...operationalDetails,
                              storageCapacity: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm={3}>
                        Security Measures
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          type="text"
                          placeholder="Describe security measures (e.g., CCTV, guards)"
                          value={operationalDetails.securityMeasures}
                          onChange={(e) =>
                            setOperationalDetails({
                              ...operationalDetails,
                              securityMeasures: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Form.Group>
                  </>
                )}
              </Card.Body>
            </Card>
          </Tab>

          {operationalModel.includes("onTheGo") && (
            <Tab eventKey={4} title="Vehicle and Worker Information">
              <Card>
                <Card.Body>
                  {/* Vehicles Information Section */}
                  <Form.Label>
                    <b>Vehicles Information</b>
                  </Form.Label>
                  {vehicleDetails.map((vehicle, index) => (
                    <div key={index}>
                      <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                          Vehicle Type
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Control
                            type="text"
                            placeholder="Vehicle Type (e.g., Truck, Van, Motorbike)"
                            value={vehicle.type}
                            onChange={(e) =>
                              handleVehicleChange(index, "type", e.target.value)
                            }
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                          Vehicle Model
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Control
                            type="text"
                            placeholder="Model"
                            value={vehicle.model}
                            onChange={(e) =>
                              handleVehicleChange(
                                index,
                                "model",
                                e.target.value
                              )
                            }
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                          Vehicle Ownership
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Select
                            aria-label="Vehicle Ownership"
                            value={vehicle.ownership}
                            onChange={(e) =>
                              handleVehicleChange(
                                index,
                                "ownership",
                                e.target.value
                              )
                            }
                          >
                            <option value="">Select Ownership</option>
                            <option value="owned">Owned</option>
                            <option value="leased">Leased</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                          License Plate Number
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Control
                            type="text"
                            placeholder="License Plate Number"
                            value={vehicle.licensePlate}
                            onChange={(e) =>
                              handleVehicleChange(
                                index,
                                "licensePlate",
                                e.target.value
                              )
                            }
                          />
                        </Col>
                      </Form.Group>
                      {/* Uploads for driver's license, vehicle insurance, and roadworthiness certificate */}
                      <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                          Driver's License
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Control
                            type="file"
                            onChange={(e) =>
                              setLegalDocuments({
                                ...legalDocuments,
                                driversLicense: e.target.files[0],
                              })
                            }
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                          Vehicle Insurance
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Control
                            type="file"
                            onChange={(e) =>
                              setLegalDocuments({
                                ...legalDocuments,
                                vehicleInsurance: e.target.files[0],
                              })
                            }
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                          Roadworthiness Certificate
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Control
                            type="file"
                            onChange={(e) =>
                              setLegalDocuments({
                                ...legalDocuments,
                                roadworthinessCertificate: e.target.files[0],
                              })
                            }
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                          Ecowas ID Card
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Control
                            type="file"
                            onChange={(e) =>
                              setLegalDocuments({
                                ...legalDocuments,
                                ecowasId: e.target.files[0],
                              })
                            }
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                          Police Report of Good Behaviour
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Control
                            type="file"
                            onChange={(e) =>
                              setLegalDocuments({
                                ...legalDocuments,
                                policeReport: e.target.files[0],
                              })
                            }
                          />
                        </Col>
                      </Form.Group>
                      <Button
                        variant="danger"
                        onClick={() => removeVehicle(index)}
                      >
                        Remove Vehicle
                      </Button>
                    </div>
                  ))}
                  <Button variant="secondary" onClick={addVehicle}>
                    Add Another Vehicle
                  </Button>

                  <hr />

                  {/* Workers Information Section */}
                  <Form.Label>
                    <b>Worker Information</b>
                  </Form.Label>
                  {workers.map((worker, index) => (
                    <div key={index}>
                      <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                          Worker Name
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Control
                            type="text"
                            placeholder="Full Name"
                            value={worker.name}
                            onChange={(e) =>
                              handleWorkerChange(index, "name", e.target.value)
                            }
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                          Worket Mobile Number
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Control
                            type="text"
                            placeholder="Worker Mobile Number"
                            value={worker.contact}
                            onChange={(e) =>
                              handleWorkerChange(
                                index,
                                "contact",
                                e.target.value
                              )
                            }
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                          Worket Email Address
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Control
                            type="text"
                            placeholder="Worker Email Address"
                            value={worker.email}
                            onChange={(e) =>
                              handleWorkerChange(index, "email", e.target.value)
                            }
                          />
                        </Col>
                      </Form.Group>
                      <Button
                        variant="danger"
                        onClick={() => removeWorker(index)}
                      >
                        Remove Worker
                      </Button>
                    </div>
                  ))}
                  <Button variant="secondary" onClick={addWorker}>
                    Add Another Worker
                  </Button>
                </Card.Body>
              </Card>
            </Tab>
          )}

          <Tab eventKey={5} title="Technology Integration">
            <Card>
              <Card.Body>
                {operationalModel.includes("onTheGo") && (
                  <>
                    {/* Technology used for real-time tracking and operations */}
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={4}>
                        GPS Tracking Capability
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Check
                          type="checkbox"
                          label="Available"
                          checked={operationalDetails.gpsTracking}
                          onChange={(e) =>
                            setOperationalDetails({
                              ...operationalDetails,
                              gpsTracking: e.target.checked,
                            })
                          }
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={4}>
                        Smartphone or Device Compatibility
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Check
                          type="checkbox"
                          label="Compatible"
                          checked={operationalDetails.deviceCompatibility}
                          onChange={(e) =>
                            setOperationalDetails({
                              ...operationalDetails,
                              deviceCompatibility: e.target.checked,
                            })
                          }
                        />
                      </Col>
                    </Form.Group>
                  </>
                )}
                {operationalModel.includes("stationary") && (
                  <>
                    {/* Technology used for order management and connectivity */}
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={4}>
                        Internet Connectivity
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Check
                          type="checkbox"
                          label="Available"
                          checked={operationalDetails.internetConnectivity}
                          onChange={(e) =>
                            setOperationalDetails({
                              ...operationalDetails,
                              internetConnectivity: e.target.checked,
                            })
                          }
                        />
                      </Col>
                    </Form.Group>
                  </>
                )}
                {/* Common technology tools that could be useful for both models */}
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Additional Technology Tools
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="text"
                      placeholder="List any additional tools"
                      value={operationalDetails.additionalTech}
                      onChange={(e) =>
                        setOperationalDetails({
                          ...operationalDetails,
                          additionalTech: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>
              </Card.Body>
            </Card>
          </Tab>

          <Tab eventKey={6} title="Financial Information">
            <Card>
              <Card.Body>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Bank Name
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Bank Name"
                      value={financialInfo.bankName}
                      onChange={(e) =>
                        setFinancialInfo({
                          ...financialInfo,
                          bankName: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Branch Name
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Branch Name"
                      value={financialInfo.branchName}
                      onChange={(e) =>
                        setFinancialInfo({
                          ...financialInfo,
                          branchName: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Account Name
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Account Name"
                      value={financialInfo.accountName}
                      onChange={(e) =>
                        setFinancialInfo({
                          ...financialInfo,
                          accountName: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={3}>
                    Account Number
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Account Number"
                      value={financialInfo.accountNumber}
                      onChange={(e) =>
                        setFinancialInfo({
                          ...financialInfo,
                          accountNumber: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={3}>
                    Mobile Payment Number (Momo)
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder="Mobile Payment Number"
                      value={financialInfo.momoNumber}
                      onChange={(e) =>
                        setFinancialInfo({
                          ...financialInfo,
                          momoNumber: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>
                    Mobile Payment Provide
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Select
                      aria-label="Mobile Payment Provider"
                      value={financialInfo.momoProvider}
                      onChange={(e) =>
                        setFinancialInfo({
                          ...financialInfo,
                          momoProvider: e.target.value,
                        })
                      }
                    >
                      <option value="">Select Mobile Payment Provider</option>
                      <option value="mtn">MTN</option>
                      <option value="vodafone">Vodafome</option>
                      <option value="airtel-tigo ">Airtel/Tigo</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={3}>
                    Perferred Payment Method
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Select
                      aria-label="Perferred Payment Method"
                      value={financialInfo.paymentMethod}
                      onChange={(e) =>
                        setFinancialInfo({
                          ...financialInfo,
                          paymentMethod: e.target.value,
                        })
                      }
                    >
                      <option value="">Select Preferred Payment Method</option>
                      <option value="Bank">MTN</option>
                      <option value="Momo">Vodafome</option>
                    </Form.Select>
                  </Col>
                </Form.Group>

                {/* Additional financial aspects specific to business models could be added here */}
                {operationalModel.includes("stationary") && (
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>
                      Cash Handling Capabilities
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Check
                        type="checkbox"
                        label="Available"
                        checked={financialInfo.cashHandling}
                        onChange={(e) =>
                          setFinancialInfo({
                            ...financialInfo,
                            cashHandling: e.target.checked,
                          })
                        }
                      />
                    </Col>
                  </Form.Group>
                )}
              </Card.Body>
            </Card>
          </Tab>

          {/* Additional tabs for other sections */}
        </Tabs>
        <Row className="mt-4">
          <Col>
            {currentStep > 1 && (
              <Button variant="secondary" onClick={handlePreviousStep}>
                Back
              </Button>
            )}
          </Col>
          <Col className="text-right">
            {currentStep < 6 && (
              <Button variant="primary" onClick={handleNextStep}>
                Next
              </Button>
            )}
          </Col>
        </Row>
        {errors.name && <p className="error">{errors.name}</p>}
        {errors.phone && <p className="error">{errors.phone}</p>}
        {/* Similarly, add other error messages */}
      </Form>
    </Container>
  );
};

export default PartnerOnboardingForm;
