import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { projectFirestore } from '../../../firebase/config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { UserContext } from '../../contexts/UserContext';
import { Alert, Form, Button, Container, Card } from 'react-bootstrap';
import PhoneNumberInput from './PhoneNumberInput'; // Assume this is your custom component for phone number

const ProfileCompletion = () => {
    const [profileData, setProfileData] = useState({
        phoneNumber: '',
        address: {
            street: '',
            postcode: '',
            suburb: '',
            city: ''
        }
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);

    useEffect(() => {
        const fetchUserProfile = async () => {
            if (currentUser) {
                const userProfileRef = doc(projectFirestore, 'users', currentUser.uid);
                const userProfileSnap = await getDoc(userProfileRef);
                if (userProfileSnap.exists()) {
                    const userData = userProfileSnap.data();
                    setProfileData({
                        phoneNumber: userData.phoneNumber || '',
                        address: userData.address || {
                            street: '',
                            postcode: '',
                            suburb: '',
                            city: ''
                        }
                    });
                } else {
                    console.error('User profile not found.');
                }
            }
        };
        fetchUserProfile();
    }, [currentUser]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name.startsWith('address.')) {
            const addressKey = name.split('.')[1];
            setProfileData(prevData => ({
                ...prevData,
                address: {
                    ...prevData.address,
                    [addressKey]: value
                }
            }));
        } else {
            setProfileData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        // if (profileData.phoneNumber && !/^\d{10}$/.test(profileData.phoneNumber)) {
        //     setError('Invalid phone number. Please enter a 10-digit phone number.');
        //     setLoading(false);
        //     return;
        // }
        if (!profileData.phoneNumber) {
            setError('Please enter a phone number.');
            setLoading(false);
            return;
          }

        try {
            const userProfileRef = doc(projectFirestore, 'users', currentUser.uid);
            // Ensure to include profileCompleted: true in the update
            await updateDoc(userProfileRef, {
                ...profileData, // Spread the existing profile data
                profileCompleted: true, // Mark the profile as completed
            });
            console.log("Updating profile with data:", profileData);

            console.log('Profile updated successfully.');
            navigate('/'); // Redirect to home or another appropriate page
        } catch (err) {
            console.error("Error updating profile:", err);
            setError('Failed to update profile. Please try again later.');
        }
        setLoading(false);
    };

    return (
        <Container className="my-5">
            <Card>
                <Card.Body>
                    <Card.Title>Edit Profile</Card.Title>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Phone Number</Form.Label>
                        <PhoneNumberInput
                            value={profileData.phoneNumber}
                            onChange={(value) => handleInputChange({ target: { value: value, name: 'phoneNumber' } })} // Adjust handleInputChange accordingly
                            placeholder="Enter phone number"
                        />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Street</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter street"
                                name="address.street"
                                value={profileData.address.street}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Postcode</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter postcode"
                                name="address.postcode"
                                value={profileData.address.postcode}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Suburb</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter suburb"
                                name="address.suburb"
                                value={profileData.address.suburb}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter city"
                                name="address.city"
                                value={profileData.address.city}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? 'Updating...' : 'Update Profile'}
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default ProfileCompletion;
