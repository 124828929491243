// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { updateProfile, createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
// import { projectAuth, projectFirestore, googleProvider } from '../../../firebase/config';

// import { doc, getDoc, setDoc } from 'firebase/firestore';  // Import Firestore methods
// import { useUser } from '../../contexts/UserContext';

// // 

// function Signup() {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [name, setName] = useState('');
//     // const [phoneNumber, setPhoneNumber] = useState('');
//     const [error, setError] = useState(null);
//     const navigate = useNavigate();
//     const { currentUser } = useUser(); // Access the currentUser from the UserContext

//     console.log("Current user:", currentUser);

//     useEffect(() => {
//         // Check if the user is authenticated and redirect if necessary
//         if (currentUser) {
//           navigate('/');
//         }
//       }, [currentUser, navigate]);

//       // Validation checks moved inside the try block for cleaner error handling
//     const handleSignup = async (e) => {
//       e.preventDefault();

//       try {
//           if (!email.includes('@')) throw new Error('Please enter a valid email.');
//           if (password.length < 6) throw new Error('Password should be at least 6 characters long.');

//           const userCredential = await createUserWithEmailAndPassword(projectAuth, email, password);
//           // After creating user, we create a user profile in Firestore
//           await setDoc(doc(projectFirestore, 'users', userCredential.user.uid), {
//               email: email,
//               displayName: name,
//               // Initial setup without phoneNumber, making it part of profile completion
//               role: 'user',  // Assign 'user' as default role
//               profileCompleted: false, // Profile completion required
//           });

//           console.log('Account created and user profile created in Firestore!');
//           navigate('/profile-completion'); // Direct user to complete their profile
//       } catch (error) {
//           setError(error.message);
//       }
//   };

//   const handleGoogleSignup = async () => {
//       try {
//           const result = await signInWithPopup(projectAuth, googleProvider);
//           const userRef = doc(projectFirestore, 'users', result.user.uid);
//           // Check if the user profile already exists in Firestore
//           await setDoc(userRef, {
//               email: result.user.email,
//               displayName: result.user.displayName || '',
//               // Initial setup without phoneNumber
//               role: 'user',
//               profileCompleted: false,
//           }, { merge: true });

//           console.log('Google sign-in successful, user profile created or updated in Firestore!');
//           navigate('/profile-completion'); // Redirect for profile completion
//       } catch (error) {
//           setError(error.message);
//       }
//   };


// return (
//     <div className="container my-5">
//       <div className="row justify-content-center">
//         <div className="col-lg-6 col-md-8">
//           <h2 className="text-center mb-4">Signup</h2>
//           {error && <div className="alert alert-danger" role="alert">{error}</div>}

//           <form onSubmit={handleSignup}>
//             <div className="form-group">
//               <input
//                 type="text"
//                 className="form-control"
//                 placeholder="Full Name"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//               />
//             </div>
         
//             <div className="form-group">
//               <input
//                 type="email"
//                 className="form-control"
//                 placeholder="Email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//             </div>
//             <div className="form-group">
//               <input
//                 type="password"
//                 className="form-control"
//                 placeholder="Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//               />
//             </div>
//             <button type="submit" className="btn btn-primary w-100 mt-3">Signup</button>
//           </form>
  
//           <button onClick={handleGoogleSignup} className="btn btn-danger w-100 mt-3">Signup with Google</button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Signup;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  updateProfile,
  createUserWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import {
  projectAuth,
  projectFirestore,
  googleProvider,
} from "../../../firebase/config";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useUser } from "../../contexts/UserContext";

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { currentUser } = useUser(); // Access the currentUser from the UserContext

  console.log("Current user:", currentUser);

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      if (!email.includes("@")) {
        throw new Error("Please enter a valid email.");
      }
      if (password.length < 6) {
        throw new Error("Password should be at least 6 characters long.");
      }

      const userCredential = await createUserWithEmailAndPassword(
        projectAuth,
        email,
        password
      );
      // After creating user, we create a user profile in Firestore
      await setDoc(doc(projectFirestore, "users", userCredential.user.uid), {
        email: email,
        displayName: name,
        role: "user", // Default role
        profileCompleted: false,
      });

      console.log("Account created and user profile set in Firestore!");
      navigate("/profile-completion"); // Redirect to complete profile
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignup = async () => {
    try {
      const result = await signInWithPopup(projectAuth, googleProvider);
      const userRef = doc(projectFirestore, "users", result.user.uid);

      await setDoc(
        userRef,
        {
          email: result.user.email,
          displayName: result.user.displayName || "",
          role: "user",
          profileCompleted: false,
        },
        { merge: true }
      );

      console.log(
        "Google sign-in successful, user profile created or updated in Firestore!"
      );
      navigate("/profile-completion"); // Redirect for profile completion
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8">
          <h2 className="text-center mb-4">Signup</h2>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          <form onSubmit={handleSignup}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-primary w-100 mt-3">
              Signup
            </button>
          </form>

          <button
            onClick={handleGoogleSignup}
            className="btn btn-danger w-100 mt-3"
          >
            Signup with Google
          </button>
        </div>
      </div>
    </div>
  );
}

export default Signup;
