import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { projectAuth, projectFirestore, googleProvider } from '../../../firebase/config';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useNavigate, Navigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';


function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { currentUser, loading } = useUser(); // Access the currentUser from the UserContext


  useEffect(() => {
    // Redirect if user is logged in and profile is complete
    if (currentUser?.profileCompleted) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(projectAuth, email, password);
      const userProfileRef = doc(projectFirestore, 'users', userCredential.user.uid);
      const userProfileSnapshot = await getDoc(userProfileRef);

      if (userProfileSnapshot.exists()) {
        const { profileCompleted, role } = userProfileSnapshot.data();

        if (role && !profileCompleted) {
          navigate('/profile-completion');
        } else if (role === 'admin') {
          navigate('/admin-dashboard');
        } else {
          navigate('/');
        }
      } else {
        setError('User profile not found. Please complete your profile.');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(projectAuth, googleProvider);
      const userProfileRef = doc(projectFirestore, 'users', result.user.uid);
      const userProfileSnapshot = await getDoc(userProfileRef);

      if (!userProfileSnapshot.exists()) {
        // For new Google users, direct them to profile completion
        navigate('/profile-completion');
      } else {
        const { profileCompleted, role } = userProfileSnapshot.data();
        
        if (role && !profileCompleted) {
          navigate('/profile-completion');
        } else if (role === 'admin') {
          navigate('/admin-dashboard');
        } else {
          navigate('/');
        }
      }
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (currentUser) {
    return <div>Redirecting...</div>;
  }

    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "50vh" }}>
        <div className="col-md-6 col-lg-4">
          <h2 className="text-center mb-4">Login</h2>
          {error && <div className="alert alert-danger" role="alert">{error}</div>}
  
          <form onSubmit={handleLogin} className="mb-3">
            <div className="mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button className="btn btn-primary w-100" type="submit">Login</button>
          </form>
  
          <button className="btn btn-danger w-100" onClick={handleGoogleLogin}>Login with Google</button>
        </div>
      </div>
    );
  }


export default Login;
