import React, { useState, useEffect } from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import { projectFirestore } from "../../../firebase/config";
import { collection, getDocs } from "firebase/firestore";

const TransportDropdown = ({
  selectedTransportCategory,
  selectedTransportSubcategory,
  selectedTransportCategoryAttributes,
  selectedTransportSubCategoryAttributes,
  setSelectedTransportCategory,
  setSelectedTransportSubcategory,
  setSelectedTransportCategoryAttributes,
  setSelectedTransportSubCategoryAttributes,
  //selectedTransportData, // Add selectedTransportData prop
}) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(projectFirestore, "DeliveryTransportCategories")
        );
        const categoriesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoriesData);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    console.log("Selected Category:", categoryId); // Add this console log
    setSelectedTransportCategory(categoryId);
    setSelectedTransportSubcategory("");
    setSelectedTransportCategoryAttributes({}); // Clear selected category attributes
    setSelectedTransportSubCategoryAttributes({}); // Clear selected subcategory attributes
  };

  const handleSubCategoryChange = (e) => {
    const subcategoryId = e.target.value;
    console.log("Selected Sub-Category:", subcategoryId); // Add this console log
    setSelectedTransportSubcategory(subcategoryId);
    setSelectedTransportSubCategoryAttributes({}); // Clear selected subcategory attributes
  };

  const handleAttributeChange = (attributeKey, option, isChecked) => {
    if (selectedTransportSubcategory) {
      setSelectedTransportSubCategoryAttributes((prevAttributes) => ({
        ...prevAttributes,
        [attributeKey]: isChecked
          ? [...(prevAttributes[attributeKey] || []), option]
          : prevAttributes[attributeKey].filter((item) => item !== option),
      }));
    } else {
      setSelectedTransportCategoryAttributes((prevAttributes) => ({
        ...prevAttributes,
        [attributeKey]: isChecked
          ? [...(prevAttributes[attributeKey] || []), option]
          : prevAttributes[attributeKey].filter((item) => item !== option),
      }));
    }

    // Update selected data in parent component
    // const updatedTransportData = {
    //   category: selectedTransportCategory,
    //   subTransportCategory: selectedTransportSubcategory,
    //   attributes: {
    //     ...(selectedTransportSubcategory
    //       ? selectedTransportSubCategoryAttributes
    //       : selectedTransportCategoryAttributes),
    //     [attributeKey]: isChecked
    //       ? [
    //           ...(selectedTransportSubcategory
    //             ? selectedTransportSubCategoryAttributes[attributeKey] || []
    //             : selectedTransportCategoryAttributes[attributeKey] || []),
    //           option,
    //         ]
    //       : selectedTransportSubcategory
    //       ? selectedTransportSubCategoryAttributes[attributeKey].filter(
    //           (item) => item !== option
    //         )
    //       : selectedTransportCategoryAttributes[attributeKey].filter(
    //           (item) => item !== option
    //         ),
    //   },
    // };

    // if (typeof selectedTransportData === "function") {
    //   selectedTransportData(updatedTransportData);
    // }
  };

  const renderAttributesInput = (attributes, isSubCategory = false) => {
    if (!attributes) return null; // Add a null check here

    const selectedAttributes = isSubCategory
      ? selectedTransportSubCategoryAttributes
      : selectedTransportCategoryAttributes;

    return Object.entries(attributes).map(([key, options], index) => {
      const optionsArray = options.split(", ");

      return (
        <div key={index}>
          <label>{key}:</label>
          {optionsArray.map((option) => (
            <div key={option}>
              <Form.Check
                type="checkbox"
                id={
                  isSubCategory
                    ? `sub-${key}-${option}`
                    : `cat-${key}-${option}`
                }
                label={option}
                checked={selectedAttributes[key]?.includes(option) || false}
                onChange={(e) =>
                  handleAttributeChange(key, option, e.target.checked)
                }
              />
            </div>
          ))}
        </div>
      );
    });
  };

  return (
    <Container>
      <Row>
        <Col>
          <Form.Label>
            <span className="fw-bold">Select Transport Category:</span>
          </Form.Label>
          <Form.Select onChange={handleCategoryChange}>
            <option value="">Select a Transport Category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>

      {selectedTransportCategory && (
        <Row>
          <Col>
            <Form.Label>
              <span className="fw-bold">
                Select Transport Category Attributes:
              </span>
            </Form.Label>
            {categories.find((cat) => cat.id === selectedTransportCategory)
              ?.attributes &&
              renderAttributesInput(
                categories.find((cat) => cat.id === selectedTransportCategory)
                  .attributes
              )}
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <Form.Label>
            <span className="fw-bold">Select Transport Sub-Category:</span>
          </Form.Label>
          <Form.Select onChange={handleSubCategoryChange}>
            <option value="">Select a Transport Sub-Category</option>
            {categories
              .find((cat) => cat.id === selectedTransportCategory)
              ?.subCategories.map((subCat) => (
                <option key={subCat.id} value={subCat.id}>
                  {subCat.name}
                </option>
              ))}
          </Form.Select>
        </Col>
      </Row>

      {selectedTransportSubcategory && (
        <Row>
          <Col>
            <Form.Label>
              {" "}
              <span className="fw-bold">
                Select Transport Subcategories Attributes:
              </span>
            </Form.Label>
            {categories
              .find((cat) => cat.id === selectedTransportCategory)
              ?.subCategories.find(
                (subCat) => subCat.id === selectedTransportSubcategory
              )?.attributes &&
              renderAttributesInput(
                categories
                  .find((cat) => cat.id === selectedTransportCategory)
                  ?.subCategories.find(
                    (subCat) => subCat.id === selectedTransportSubcategory
                  )?.attributes,
                true
              )}
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default TransportDropdown;
