import React, { useState, useEffect } from "react";
import { projectFirestore } from "../../../firebase/config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";

const DeliveryPartnerAdminManager = () => {
  const [partnerId, setPartnerId] = useState("");
  const [partnerData, setPartnerData] = useState(null);
  const [editableFields, setEditableFields] = useState({});
  const [nonEditableFields, setNonEditableFields] = useState({});
  const fieldMapping = {
    userId: "User ID",
    createdAt: "Created At",
    // Add more mappings as needed
  };
  const [status, setStatus] = useState("On-Hold"); // State variable for status field
  const fieldOrder = [
    "fullBusinessName",
    "id",
    "contactInfo",
    "paymentDetails",
    "hasTaxIdentificationNumber",
    "hasRegistrationCertificate",
    "taxInformation",
    "insuranceInfo",
    "partnershipType",
    "partnerType",
    "workerData",
    "geolocation",
    "availability",
    "operationalInfo",
    "experience",
    "personalIdentificationUrl",
    "backgroundCheckReportUrl",
    "miscellaneousUploadUrl",
    "deliveryCategories",
    "deliverySubcategories",
    "selectedDeliveryAttributes",
    "transportCategories",
    "transportSubcategories",
    "selectedTransportAttributes",
    "createdAt",
    "userId",
    "status" /* Add more fields here */,
  ];

  const fieldDisplayNameMapping = {
    fullBusinessName: "Full Business Name",
    id: "Partner ID",
    contactInfo: "Contact Info",
    paymentDetails: "Payment Details",
    hasTaxIdentificationNumber: "Has Tax Identification Number",
    hasRegistrationCertificate: "Has Registration Certificate",
    taxInformation: "Tax ID Number",
    insuranceInfo: "Insurance Info",
    partnershipType: "Partnership Type",
    partnerType: "Partner Type",
    workerData: "Partner Workers Records",
    geolocation: "Partner Geolocation",
    availability: "Availability",
    operationalInfo: "Operational Info",
    experience: "Experience",
    personalIdentificationUrl: "Ecowas ID Card",
    backgroundCheckReportUrl: "Background Police report",
    miscellaneousUploadUrl: "Miscellaneous Report",
    deliveryCategories: "Selected Delivery Categories",
    deliverySubcategories: "Selected Delivery Subcategories",
    selectedDeliveryAttributes: "Selected Delivery Attributes",
    transportCategories: "Selected Transport Categories",
    transportSubcategories: "Selected Transport Subcategories",
    selectedTransportAttributes: "Selected Transport Attributes",
    createdAt: "Created At",
    userId: "Partner ID",
    status: "Status",
    // Add more mappings as needed
  };

  const handleInputChange = (e) => {
    setPartnerId(e.target.value);
  };

  const fetchPartnerData = async () => {
    let partnerSnapshot; // Declare partnerSnapshot variable
    try {
      const partnerRef = doc(projectFirestore, "deliveryPartner", partnerId);
      partnerSnapshot = await getDoc(partnerRef); // Assign value inside try block
      if (partnerSnapshot.exists()) {
        setPartnerData({ id: partnerSnapshot.id, ...partnerSnapshot.data() });
        initializeEditableFields(partnerSnapshot.data());
      } else {
        setPartnerData(null);
        setEditableFields({});
      }
    } catch (error) {
      console.error("Error fetching delivery partner data:", error);
    }
  };

  const initializeEditableFields = (partnerData) => {
    const editableFields = {};
    const nonEditableFields = {};

    // Define which fields are editable
    // For example, allow editing of partnerType and status
    editableFields.partnerType = partnerData.partnerType;
    editableFields.status = partnerData.status;

    // Define which fields are non-editable
    // For example, userId and createdAt
    nonEditableFields.userId = partnerData.userId;
    nonEditableFields.createdAt = partnerData.createdAt;
    // nonEditableFields.employees = partnerData.employees;
    nonEditableFields.fullBusinessName = partnerData.fullBusinessName;
    nonEditableFields.taxInformation = partnerData.taxInformation;
    nonEditableFields.hasTaxIdentificationNumber =
      partnerData.hasTaxIdentificationNumber;
    nonEditableFields.paymentDetails = partnerData.paymentDetails;
    // nonEditableFields.experience = partnerData.experience;
    nonEditableFields.geolocation = partnerData.geolocation;
    nonEditableFields.workerData = partnerData.workerData;
    nonEditableFields.contactInfo = partnerData.contactInfo;
    nonEditableFields.hasRegistrationCertificate =
      partnerData.hasRegistrationCertificate;

    setEditableFields(editableFields);
    setNonEditableFields(nonEditableFields);
  };

  const handleApprove = async () => {
    try {
      if (partnerData) {
        const partnerRef = doc(
          projectFirestore,
          "deliveryPartner",
          partnerData.id
        );
        await updateDoc(partnerRef, { status: "Approved" });
        // Refresh partner data after update
        fetchPartnerData();
      }
    } catch (error) {
      console.error("Error approving request:", error);
    }
  };

  const handleReject = async () => {
    try {
      if (partnerData) {
        const partnerRef = doc(
          projectFirestore,
          "deliveryPartner",
          partnerData.id
        );
        await updateDoc(partnerRef, { status: "Rejected" });
        // Refresh partner data after update
        fetchPartnerData();
      }
    } catch (error) {
      console.error("Error rejecting request:", error);
    }
  };

  const handleFieldChange = (fieldName, value) => {
    setEditableFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  const handleUpdate = async () => {
    try {
      if (partnerData) {
        const partnerRef = doc(
          projectFirestore,
          "deliveryPartner",
          partnerData.id
        );
        await updateDoc(partnerRef, { ...partnerData, status });
        // Refresh partner data after update
        fetchPartnerData();
      }
    } catch (error) {
      console.error("Error updating partner data:", error);
    }
  };

  const renderImagePreview = (imageUrl) => {
    return (
      <div>
        <img
          src={imageUrl}
          alt="Preview"
          style={{ maxWidth: "200px", maxHeight: "200px" }}
        />
        <a href={imageUrl} target="_blank" rel="noopener noreferrer">
          View Full Image
        </a>
      </div>
    );
  };

  const renderField = (key, value) => {
    if (key === "createdAt") {
      const date = new Date(value.seconds * 1000); // Convert Firestore timestamp to JavaScript Date
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      return <div>{date.toLocaleDateString("en-GB", options)}</div>; // Render date in European format
    } else if (key === "geolocation") {
      return (
        <div>
          <div>
            Latitude:{" "}
            <input
              type="text"
              value={value.latitude}
              onChange={(e) =>
                handleFieldChange("geolocation", {
                  ...value,
                  latitude: e.target.value,
                })
              }
            />
          </div>
          <div>
            Longitude:{" "}
            <input
              type="text"
              value={value.longitude}
              onChange={(e) =>
                handleFieldChange("geolocation", {
                  ...value,
                  longitude: e.target.value,
                })
              }
            />
          </div>
        </div>
      );
    } else if (key === "contactInfo") {
      return (
        <div>
          {Object.entries(value).map(([subKey, subValue]) => (
            <div key={subKey}>
              {subKey === "email" || subKey === "phone" ? (
                <div>
                  <span className="fw-bold">
                    {subKey.charAt(0).toUpperCase() + subKey.slice(1)}
                  </span>
                  : {subValue}
                </div>
              ) : (
                <div>
                  {subKey}: {subValue}
                </div>
              )}
            </div>
          ))}
        </div>
      );
    } else if (key === "paymentDetails") {
      return (
        <div>
          {Object.entries(value).map(([subKey, subValue]) => (
            <div key={subKey}>
              {subKey === "preferredPaymentMethod" ? (
                <div>
                  <span className="fw-bold">Preferred Payment Method:</span>{" "}
                  {subValue}
                </div>
              ) : subKey === "mobileAccount" ? (
                <div>
                  <span className="fw-bold">Mobile Account:</span> {subValue}
                </div>
              ) : subKey === "bankAccount" ? (
                <div>
                  <span className="fw-bold">Bank Account:</span> {subValue}
                </div>
              ) : (
                <div>
                  {subKey}: {subValue}
                </div>
              )}
            </div>
          ))}
        </div>
      );
    } else if (key === "workerData") {
      return (
        <div>
          {value.map((worker, index) => (
            <div key={index}>
              <div className="fw-bold">Worker {index + 1}</div>
              {Object.entries(worker).map(([subKey, subValue]) => (
                <div key={subKey}>
                  {subKey === "fullName" ? (
                    <div>
                      <span className="fw-bold">Full Name:</span> {subValue}
                    </div>
                  ) : subKey === "email" ? (
                    <div>
                      <span className="fw-bold">Email:</span> {subValue}
                    </div>
                  ) : subKey === "mobileNumber" ? (
                    <div>
                      <span className="fw-bold">Mobile Number:</span> {subValue}
                    </div>
                  ) : (
                    <div>
                      {subKey}: {subValue}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    } else if (key === "id" || nonEditableFields[key]) {
      return (
        <input
          type="text"
          value={value}
          readOnly
          style={{ backgroundColor: "lightgray" }}
        />
      );
    } else if (key.endsWith("Url")) {
      return <div>{renderImagePreview(value)}</div>;
    } else {
      // Handle rendering of newly added fields (categories, subcategories, attributes)
      if (["deliveryCategories", "deliverySubcategories"].includes(key)) {
        // Check if value is an array before joining
        if (Array.isArray(value)) {
          return <div>{value.join(", ")}</div>;
        } else {
          // Handle non-array cases (e.g., display as-is)
          return <div>{value}</div>;
        }
      } else if (
        ["partnershipType", "vehicleTypes", "workerData"].includes(key) &&
        Array.isArray(value)
      ) {
        // Render comma-separated lists for arrays of strings
        return <div>{value.join(", ")}</div>;
      } else if (
        ["selectedDeliveryAttributes", "selectedTransportAttributes"].includes(
          key
        )
      ) {
        if (typeof value === "object") {
          // Object found, iterate and render elements
          return (
            <div>
              {Object.entries(value).map(([attributeKey, attributeValue]) => (
                <div key={attributeKey}>
                  {/* Render attribute details (e.g., attribute name and value) */}
                  {attributeKey}: {attributeValue}
                </div>
              ))}
            </div>
          );
        } else {
          // Handle non-object cases (e.g., display as-is)
          return <div>{value}</div>;
        }
      } else {
        return <div>{value}</div>; // Default rendering
      }
    }
  };

  return (
    <Container>
      <h2 className="mt-5">Delivery Partner Admin Manager</h2>
      {!partnerData && ( // Only render if partnerData is not available
        <Row className="mb-3">
          <Col>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Enter Delivery Partner ID or Name:</Form.Label>
                <Form.Control
                  type="text"
                  id="partnerId"
                  name="partnerId"
                  value={partnerId}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Button variant="primary" onClick={fetchPartnerData}>
                Fetch Data
              </Button>
            </Form>
          </Col>
        </Row>
      )}
      {partnerData && (
        <div>
          <h4> </h4>
          
          {fieldOrder.map((key) => {
            const value = partnerData[key];
            const displayName = fieldDisplayNameMapping[key] || key; // Use display name if available, otherwise use key

            return (
              <Row key={key} className="mb-3">
                <Col xs={3} className="fw-bold">
                  {displayName}
                </Col>
                <Col xs={9}>
                  {key === "status" ? (
                    <Form.Select
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="On-Hold">On-Hold</option>
                      <option value="Progressing">Progressing</option>
                      <option value="Achieved">Achieved</option>
                      <option value="Suspended">Suspended</option>
                      <option value="Approved">Approved</option>
                    </Form.Select>
                  ) : (
                    renderField(key, value)
                  )}
                </Col>
              </Row>
            );
          })}
          <Row className="mt-3">
            <Button variant="success" className="me-2" onClick={handleUpdate}>
              Update
            </Button>
          </Row>
        </div>
      )}
    </Container>
  );
};

export default DeliveryPartnerAdminManager;
