import React from 'react';
import { useOrder } from '../contexts/OrderContext';

function OrderSummary({ selectedServiceDetails, formData, selectedServicePrice, calculatedPrice, onConfirm, onEdit }) {
    const { orderDetails } = useOrder();
    const selectedServiceName = selectedServiceDetails?.name; // Ensure this is being passed in as a prop to OrderSummary

    // console.log("Received order details in summary: ", orderDetails);
    // Use selectedServiceName for comparison
    const isCompleteHouseCleaning = selectedServiceName === 'Complete House Cleaning';


    return (
        <div className="bg-light p-4 rounded mb-4">
            <h2>Order Summary</h2>
            <p className="fw-bold">Service Selected: <span className="fw-normal">{selectedServiceName || 'Not Specified'}</span></p>
            {/* <p className="fw-bold">Service Selected: <span className="fw-normal">{orderDetails.selectedService || 'Not Specified'}</span></p> */}
            <p className="fw-bold">Service Date: <span className="fw-normal">{formData.serviceDate || 'Not Specified'}</span></p>
            <p className="fw-bold">Service Time: <span className="fw-normal">{formData.serviceTime || 'Not Specified'}</span></p>
            <p className="fw-bold">Calculated Price: <span className="fw-normal">GHS {calculatedPrice}</span></p>


            {/* Displaying details specific to the selected service */}
            {isCompleteHouseCleaning && (
                <>
                    <p className="fw-bold">Number of Bedrooms: <span className="fw-normal">{orderDetails.bedrooms || 'Not Specified'}</span></p>
                    <p className="fw-bold">Number of Bathrooms: <span className="fw-normal">{orderDetails.bathrooms || 'Not Specified'}</span></p>
                    <p className="fw-bold">Home Size: <span className="fw-normal">{orderDetails.homeSize || 'Not Specified'}</span></p>
                    <p className="fw-bold">Cleaning Frequency: <span className="fw-normal">{orderDetails.frequency || 'Not Specified'}</span></p>                </>
            )}

            {orderDetails.selectedService === 'Kitchen Cleaning' && (
                <>
                    <p className="fw-bold">Number of Kitchens: <span className="fw-normal">{orderDetails.kitchens || 'Not Specified'}</span></p>
                    <p className="fw-bold">Additional Information: <span className="fw-normal">{orderDetails.additionalInfo || 'Not Specified'}</span></p>
                    <p className="fw-bold">Cleaning Frequency: <span className="fw-normal">{orderDetails.frequency || 'Not Specified'}</span></p>
                </>
            )}

            {orderDetails.selectedService === 'Dusting' && (
                <>
                    <p className="fw-bold">Number of Items to Dust: <span className="fw-normal">{orderDetails.itemsToDust || 'Not Specified'}</span></p>
                    <p className="fw-bold">Additional Information: <span className="fw-normal">{orderDetails.additionalInfo || 'Not Specified'}</span></p>
                    <p className="fw-bold">Cleaning Frequency: <span className="fw-normal">{orderDetails.frequency || 'Not Specified'}</span></p>
                </>
            )}

            {/* Displaying details specific to the selected service */}
            {orderDetails.selectedService === 'Fridge Cleaning' && (
                <>
                    <p className="fw-bold">Number of Fridges: <span className="fw-normal">{orderDetails.fridges || 'Not Specified'}</span></p>
                    <p className="fw-bold">Additional Information: <span className="fw-normal">{orderDetails.additionalInfo || 'Not Specified'}</span></p>
                    <p className="fw-bold">Cleaning Frequency: <span className="fw-normal">{orderDetails.frequency || 'Not Specified'}</span></p>
                </>
            )}

            {orderDetails.selectedService === 'Oven Cleaning' && (
                <>
                    <p className="fw-bold">Number of Ovens: <span className="fw-normal">{orderDetails.ovens || 'Not Specified'}</span></p>
                    <p className="fw-bold">Additional Information: <span className="fw-normal">{orderDetails.additionalInfo || 'Not Specified'}</span></p>
                    <p className="fw-bold">Cleaning Frequency: <span className="fw-normal">{orderDetails.frequency || 'Not Specified'}</span></p>
                </>
            )}
            
            {orderDetails.selectedService === 'Bathroom Cleaning' && (
                <>
                    <p className="fw-bold">Number of Bathrooms: <span className="fw-normal">{orderDetails.bathrooms || 'Not Specified'}</span></p>
                    <p className="fw-bold">Additional Information: <span className="fw-normal">{orderDetails.additionalInfo || 'Not Specified'}</span></p>
                    <p className="fw-bold">Cleaning Frequency: <span className="fw-normal">{orderDetails.frequency || 'Not Specified'}</span></p>
                </>
            )}

            {orderDetails.selectedService === 'Cabinets Cleaning' && (
                <>
                    <p className="fw-bold">Number of Cabinets: <span className="fw-normal">{orderDetails.cabinets || 'Not Specified'}</span></p>
                    <p className="fw-bold">Additional Information: <span className="fw-normal">{orderDetails.additionalInfo || 'Not Specified'}</span></p>
                    <p className="fw-bold">Cleaning Frequency: <span className="fw-normal">{orderDetails.frequency || 'Not Specified'}</span></p>
                </>
            )}
            
            {orderDetails.selectedService === 'Deck or Garage Sweeping' && (
                <>
                    <p className="fw-bold">Number of Decks or Garages: <span className="fw-normal">{orderDetails.decksOrGarages || 'Not Specified'}</span></p>
                    <p className="fw-bold">Additional Information: <span className="fw-normal">{orderDetails.additionalInfo || 'Not Specified'}</span></p>
                    <p className="fw-bold">Cleaning Frequency: <span className="fw-normal">{orderDetails.frequency || 'Not Specified'}</span></p>
                </>
            )}
            
            {orderDetails.selectedService === 'Any Other Cleaning' && (
                <>
                    <p className="fw-bold">Items or Areas to Clean: <span className="fw-normal">{orderDetails.itemsToClean || 'Not Specified'}</span></p>
                    <p className="fw-bold">Additional Information: <span className="fw-normal">{orderDetails.additionalInfo || 'Not Specified'}</span></p>
                    <p className="fw-bold">Cleaning Frequency: <span className="fw-normal">{orderDetails.frequency || 'Not Specified'}</span></p>
                </>
            )}
            
            {orderDetails.selectedService === 'Tailored Cleaning Service' && (
                <>
                    <p className="fw-bold">Number of Tailored Items: <span className="fw-normal">{orderDetails.tailoredItems || 'Not Specified'}</span></p>
                    <p className="fw-bold">Specific Cleaning Requests: <span className="fw-normal">{orderDetails.specificRequests || 'Not Specified'}</span></p>
                    <p className="fw-bold">Cleaning Frequency: <span className="fw-normal">{orderDetails.frequency || 'Not Specified'}</span></p>
                </>
            )}

            {orderDetails.selectedService === 'Hard Floor Cleaning' && (
                <>
                    <p className="fw-bold">Number of Hard Floors to Clean: <span className="fw-normal">{orderDetails.hardFloors || 'Not Specified'}</span></p>
                    <p className="fw-bold">Additional Information: <span className="fw-normal">{orderDetails.additionalInfo || 'Not Specified'}</span></p>
                    <p className="fw-bold">Cleaning Frequency: <span className="fw-normal">{orderDetails.frequency || 'Not Specified'}</span></p>
                </>
            )}

            {orderDetails.selectedService === 'Carpet Vacuuming' && (
                <>
                    <p className="fw-bold">Number of Carpets to Vacuum: <span className="fw-normal">{orderDetails.carpets || 'Not Specified'}</span></p>
                    <p className="fw-bold">Additional Information: <span className="fw-normal">{orderDetails.additionalInfo || 'Not Specified'}</span></p>
                    <p className="fw-bold">Cleaning Frequency: <span className="fw-normal">{orderDetails.frequency || 'Not Specified'}</span></p>
                </>
            )}

            <div className="d-flex justify-content-between mt-4">
                <button onClick={onEdit} className="btn btn-warning">Edit</button>
                <button onClick={onConfirm} className="btn btn-success">Confirm</button>
            </div>
        </div>
    );
}

export default OrderSummary;
