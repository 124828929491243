import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { projectFirestore } from '../../firebase/config';
import { Button, Table } from 'react-bootstrap';
import { useParams, Link, useNavigate } from 'react-router-dom';

const PolicyList = () => {
    const [policies, setPolicies] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPolicies = async () => {
            const querySnapshot = await getDocs(collection(projectFirestore, 'policies'));
            const fetchedPolicies = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setPolicies(fetchedPolicies);
        };

        fetchPolicies();
    }, []);

    const handleEdit = (policyId) => {
        // Navigate to PolicyEditor for editing
        navigate(`/edit-policy/${policyId}`);
    };

    const handleDelete = async (policyId) => {
        // Delete policy
        await deleteDoc(doc(projectFirestore, 'policies', policyId));
        // Refresh the list after deletion
        setPolicies(policies.filter(policy => policy.id !== policyId));
    };

    return (
      <div className="container mt-3 pt-3">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Policy Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {policies.map((policy) => (
              <tr key={policy.id}>
                <td>{policy.name}</td>
                <td>
                  <Button
                    variant="primary"
                    onClick={() => handleEdit(policy.id)}
                  >
                    Edit
                  </Button>{" "}
                  <Button
                    variant="danger"
                    onClick={() => handleDelete(policy.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
};

export default PolicyList;
