// utils/geolocation.js

const fetchGeolocation = (successCallback, errorCallback) => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        successCallback({ latitude, longitude }); // Call success callback with geolocation object
      },
      (error) => {
        errorCallback(`Error fetching geolocation: ${error.message}`); // Call error callback with error message
      }
    );
  } else {
    errorCallback("Geolocation is not supported by this browser."); // Call error callback if geolocation is not supported
  }
};

export default fetchGeolocation;
