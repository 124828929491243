import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { collection, getDocs, query, where } from "firebase/firestore"; // Importing functions from firebase/firestore
import { projectFirestore } from "../../../firebase/config";
import { useNavigate } from "react-router-dom";
import ContactDeliveryPerson from "./ContactDeliveryPerson";
import DeliveryInstructions from "./DeliveryInstructions";
import RescheduleDelivery from "./RescheduleDelivery";
import ViewPackageDetails from "./ViewPackageDetails";

function Receivals() {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [deliveryOrders, setDeliveryOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showContactDeliveryPerson, setShowContactDeliveryPerson] =
    useState(false);
  const [showDeliveryInstructions, setShowDeliveryInstructions] =
    useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [showPackageDetails, setShowPackageDetails] = useState(false);
  const [showViewDetails, setShowViewDetails] = useState(false);

  const formatAddress = (address) => {
    return address
      ? `${address.street}, ${address.city}`
      : "Address not provided";
  };

  const formatDate = (timestamp) => {
    return timestamp
      ? new Date(timestamp.seconds * 1000).toLocaleDateString()
      : "Date not provided";
  };

  useEffect(() => {
    const fetchDeliveryOrders = async () => {
      if (!currentUser) {
        console.log("No user logged in.");
        return;
      }

      setLoading(true);
      try {
        const ordersRef = collection(projectFirestore, "deliveryorders");
        const querySnapshot = await getDocs(
          query(ordersRef, where("userId", "==", currentUser.uid))
        );

        if (querySnapshot.empty) {
          console.log("No delivery orders for this user.");
          setLoading(false);
          return;
        }

        const orders = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            orderStatus: data.orderStatus,
            pickupAddress: data.selectedPickupAddress,
            deliveryAddress: data.selectedDeliveryAddress,
            packageData: data.packageDetails,
            estimatedPickupDate: formatDate(data.packageDetails.pickupDate),
            estimatedDeliveryDate: formatDate(data.packageDetails.deliveryDate),
          };
        });
        setDeliveryOrders(orders);
      } catch (error) {
        console.error("Error fetching delivery orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDeliveryOrders();
  }, [currentUser]);

  const renderOrdersTable = (orders) => (
    <table>
      <thead>
        <tr>
          <th>Order ID</th>
          <th>Pickup Address</th>
          <th>Delivery Address</th>
          <th>Order Status</th>
          <th>Estimated Pickup</th>
          <th>Estimated Delivery</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {orders.map((order) => (
          <tr key={order.id}>
            <td>{order.id}</td>
            <td>{formatAddress(order.pickupAddress)}</td>
            <td>{formatAddress(order.deliveryAddress)}</td>
            <td>{order.orderStatus}</td>
            <td>{order.estimatedPickupDate}</td>
            <td>{order.estimatedDeliveryDate}</td>
            <td>
              <button onClick={() => handleViewDetails(order)}>
                View Details
              </button>
              <button onClick={() => handleContactDeliveryPerson(order)}>
                Contact Delivery
              </button>
              <button onClick={() => handleEditInstructions(order)}>
                Edit Instructions
              </button>
              <button onClick={() => handleReschedule(order)}>
                Reschedule
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const handleContactDeliveryPerson = (order) => {
    setSelectedOrder(order);
    setShowContactDeliveryPerson(true);
  };

  const handleEditInstructions = (order) => {
    setSelectedOrder(order);
    setShowDeliveryInstructions(true);
  };

  const handleReschedule = (order) => {
    setSelectedOrder(order);
    setShowReschedule(true);
  };

  const handleViewDetails = (order) => {
    setSelectedOrder(order);
    setShowViewDetails(true);
  };

  const handleClose = () => {
    setShowContactDeliveryPerson(false);
    setShowDeliveryInstructions(false);
    setShowReschedule(false);
    setShowPackageDetails(false);
    setSelectedOrder(null);
  };

  const handleCloseViewDetails = () => {
    setShowViewDetails(false);
    setSelectedOrder(null);
  };

  if (loading) {
    return <div>Loading delivery orders...</div>;
  }

  return (
    <>
      <div>
        <h1>My Delivery Orders</h1>
        {deliveryOrders.length > 0 ? (
          <>
            {renderOrdersTable(
              deliveryOrders.filter((order) => order.orderStatus === "pending")
            )}
            {renderOrdersTable(
              deliveryOrders.filter(
                (order) => order.orderStatus === "in_transit"
              )
            )}
            {renderOrdersTable(
              deliveryOrders.filter(
                (order) => order.orderStatus === "delivered"
              )
            )}
          </>
        ) : (
          <p>No delivery orders available.</p>
        )}
      </div>

      {showContactDeliveryPerson && selectedOrder && (
        <ContactDeliveryPerson
          deliveryPersonContact={selectedOrder.deliveryPersonContact}
          onClose={handleClose}
        />
      )}

      {showDeliveryInstructions && selectedOrder && (
        <DeliveryInstructions
          orderId={selectedOrder.id}
          onClose={handleClose}
        />
      )}

      {showReschedule && selectedOrder && (
        <RescheduleDelivery orderId={selectedOrder.id} onClose={handleClose} />
      )}

      {showViewDetails && selectedOrder && selectedOrder.packageData && (
        <ViewPackageDetails
          senderName={selectedOrder.pickupAddress.fullName}
          senderAddress={`${selectedOrder.pickupAddress.street}, ${selectedOrder.pickupAddress.city}`}
          deliveryName={selectedOrder.deliveryAddress.fullName}
          deliveryAddress={`${selectedOrder.deliveryAddress.street}, ${selectedOrder.deliveryAddress.city}`}
          dimensions={selectedOrder.packageData.dimensions}
          weight={selectedOrder.packageData.weight}
          cost={selectedOrder.deliveryCost}
          onClose={handleCloseViewDetails}
        />
      )}
    </>
  );
}

export default Receivals;
