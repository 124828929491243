import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap"; // Importing components from react-bootstrap

import fetchGeolocation from "../utils/fetchGeolocation";


const AddEditAddressForm = ({ address, onSave, onCancel }) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const [formState, setFormState] = useState({
    fullName: "",
    street: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    latitude: "",
    longitude: "",
    addressType: "",
  });

  useEffect(() => {
    // Initialize form with address data or blank
    if (address) {
      setFormState({
        fullName: address.fullName || "",
        street: address.street || "",
        city: address.city || "",
        state: address.state || "",
        zipCode: address.zipCode || "",
        country: address.country || "",
        latitude: address.latitude || "0.0", // Ensure default values or retain existing
        longitude: address.longitude || "0.0",
        addressType: address.addressType || "",
      });
    } else {
      setFormState({
        fullName: "",
        street: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        latitude: "",
        longitude: "",
        addressType: "",
      });
    }
  }, [address]);


const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormState((prevState) => ({
    ...prevState,
    [name]: value,
  }));
};

const handleFetchGeolocation = () => {
  fetchGeolocation(
    (geo) => {
      setFormState((prevState) => ({
        ...prevState,
        latitude: geo.latitude.toString(),
        longitude: geo.longitude.toString(),
      }));
      setError(""); // Clear any previous errors
    },
    (error) => {
      console.error("Geolocation error:", error);
      setError(error);
    }
  );
};
 

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formState.addressType) {
      alert("Please select an address type.");
      return;
    }

    onSave(formState);
  };

  return (
    <Form onSubmit={handleSubmit} className="mt-4">
      <Form.Group controlId="fullName" className="mb-3">
        <Form.Label>Full Name</Form.Label>
        <Form.Control
          type="text"
          name="fullName"
          value={formState.fullName}
          onChange={handleInputChange}
          placeholder="Enter full name"
        />
      </Form.Group>
      <Form.Group controlId="street" className="mb-3">
        <Form.Label>Street</Form.Label>
        <Form.Control
          type="text"
          name="street"
          value={formState.street}
          onChange={handleInputChange}
          placeholder="Enter street"
        />
      </Form.Group>
      <Form.Group controlId="city" className="mb-3">
        <Form.Label>City</Form.Label>
        <Form.Control
          type="text"
          name="city"
          value={formState.city}
          onChange={handleInputChange}
          placeholder="Enter city"
        />
      </Form.Group>
      <Form.Group controlId="state" className="mb-3">
        <Form.Label>Region</Form.Label>
        <Form.Control
          type="text"
          name="state"
          value={formState.state}
          onChange={handleInputChange}
          placeholder="Enter state"
        />
      </Form.Group>
      <Form.Group controlId="zipCode" className="mb-3">
        <Form.Label>Post Code</Form.Label>
        <Form.Control
          type="text"
          name="zipCode"
          value={formState.zipCode}
          onChange={handleInputChange}
          placeholder="Enter zip code"
        />
      </Form.Group>
      <Form.Group controlId="country" className="mb-3">
        <Form.Label>Country</Form.Label>
        <Form.Control
          type="text"
          name="country"
          value={formState.country}
          onChange={handleInputChange}
          placeholder="Enter country"
        />
      </Form.Group>
      <Button variant="primary" onClick={handleFetchGeolocation}>
        Fetch Geolocation
      </Button>
      {error && <p className="text-danger">{error}</p>}
      <Form.Group controlId="latitude" className="mb-3">
        <Form.Label>Latitude:</Form.Label>
        <Form.Control
          type="text"
          name="latitude"
          value={formState.latitude}
          onChange={handleInputChange}
          placeholder="Enter latitude"
        />
      </Form.Group>
      <Form.Group controlId="longitude" className="mb-3">
        <Form.Label>Longitude:</Form.Label>
        <Form.Control
          type="text"
          name="longitude"
          value={formState.longitude}
          onChange={handleInputChange}
          placeholder="Enter longitude"
        />
      </Form.Group>
      <Form.Group controlId="addressType" className="mb-3">
        <Form.Label>Address Type</Form.Label>
        <Form.Select
          name="addressType"
          value={formState.addressType}
          onChange={handleInputChange}
          required
        >
          <option value="" disabled>
            Select Address Type
          </option>
          <option value="Pickup">Pickup</option>
          <option value="Delivery">Delivery</option>
        </Form.Select>
      </Form.Group>
      <Button variant="primary" type="submit" className="me-2">
        Save
      </Button>
      <Button variant="secondary" onClick={onCancel}>
        Cancel
      </Button>
    </Form>
  );
};

export default AddEditAddressForm;
