import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

const ContactDeliveryPerson = ({ deliveryPersonContact }) => {
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to send message to the delivery person
    console.log(`Message to delivery person: ${message}`);
    // Reset the message input after sending
    setMessage("");
  };

  return (
    <div className="contact-container">
      <h3 className="title">Contact Delivery Person</h3>
      <Form className="contact-form" onSubmit={handleSubmit}>
        <Form.Control
          as="textarea"
          className="message-input"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter your message or special delivery instructions"
          required
        />
        <Button className="send-button" type="submit">
          Send Message
        </Button>
      </Form>
    </div>
  );
};

export default ContactDeliveryPerson;
