import React, { useState, useEffect, useContext } from "react";
import { Button, Card, ListGroup } from "react-bootstrap"; // Import Bootstrap components
import {
  doc,
  getFirestore,
  updateDoc,
  addDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { projectFirestore } from "../../firebase/config";
import AddEditAddressForm from "./AddEditAddressForm";
import { CountryDropdown } from "react-country-region-selector";
import { useAddressUpdate } from "../contexts/AddressUpdateContext";

const AddressManager = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { setAddressUpdated } = useAddressUpdate();
  const [selectedCountry, setSelectedCountry] = useState("Ghana");
  const [addresses, setAddresses] = useState([]);
  const [editingAddress, setEditingAddress] = useState(null);
  const [expandedAddresses, setExpandedAddresses] = useState(new Set());

  const navigateToSendings = () => {
    navigate("/sendings");
  };

  useEffect(() => {
    const fetchAddresses = async () => {
      if (currentUser && currentUser.uid) {
        const addressesRef = collection(projectFirestore, "shippingAddresses");
        const q = query(addressesRef, where("userId", "==", currentUser.uid));

        try {
          const querySnapshot = await getDocs(q);

          const fetchedAddresses = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            latitude: doc.data().latitude || "",
            longitude: doc.data().longitude || "",
          }));

          setAddresses(fetchedAddresses);
        } catch (error) {
          console.error("Error fetching addresses:", error);
        }
      }
    };

    fetchAddresses();
  }, [currentUser]);

  const toggleAddressView = (addressId) => {
    setExpandedAddresses((prev) => {
      const newExpanded = new Set(prev);
      if (newExpanded.has(addressId)) {
        newExpanded.delete(addressId);
      } else {
        newExpanded.add(addressId);
      }
      return newExpanded;
    });
  };

  const handleEdit = (address) => {
    setEditingAddress(address);
  };

  const handleDelete = async (addressId) => {
    await projectFirestore
      .collection("shippingAddresses")
      .doc(addressId)
      .delete();
    setAddresses(addresses.filter((addr) => addr.id !== addressId));
  };

  const handleAddShippingAddress = async (newAddress) => {
    try {
      const docRef = await addDoc(
        collection(projectFirestore, "shippingAddresses"),
        {
          ...newAddress,
          userId: currentUser.uid,
          country: selectedCountry, // Save selected country with the address
        }
      );

      setAddresses([...addresses, { id: docRef.id, ...newAddress }]);
      setAddressUpdated(true);
    } catch (error) {
      console.error("Error adding address: ", error);
    }
  };

  // const handleEditShippingAddress = async (addressId, updatedAddress) => {
  //   try {
  //     const addressRef = doc(projectFirestore, "shippingAddresses", addressId);
  //     await updateDoc(addressRef, {
  //       ...updatedAddress,
  //       country: selectedCountry, // Ensure 'selectedCountry' is in scope
  //     });

  //     // Update the addresses state to reflect the changes
  //     setAddresses(
  //       addresses.map((addr) =>
  //         addr.id === addressId ? { ...addr, ...updatedAddress } : addr
  //       )
  //     );

  //     // Set address update flag to true
  //     setAddressUpdated(true);
  //   } catch (error) {
  //     console.error("Error updating address: ", error);
  //   }
  // };

const handleEditShippingAddress = async (addressId, updatedAddress) => {
  try {
    // Find the existing address to ensure all data, including unedited fields like latitude and longitude, are retained.
    const existingAddress = addresses.find((addr) => addr.id === addressId);

    // Create the address update object by merging existing address data with the updated data.
    // This approach retains all fields, especially those not included in the form like latitude and longitude.
    const addressUpdate = {
      ...existingAddress, // Spread existing data
      ...updatedAddress, // Override with new data
      country: selectedCountry, // Explicitly set the country from the selectedCountry state
    };

    // Reference the document in Firestore to update
    const addressRef = doc(projectFirestore, "shippingAddresses", addressId);

    // Perform the update in Firestore
    await updateDoc(addressRef, addressUpdate);

    // Update the local state to reflect the changes
    setAddresses(
      addresses.map((addr) =>
        addr.id === addressId ? { ...addr, ...addressUpdate } : addr
      )
    );

    // Notify that the address update was successful
    setAddressUpdated(true);
  } catch (error) {
    console.error("Error updating address: ", error);
  }
};



  const handleDeleteShippingAddress = async (addressId) => {
    try {
      await projectFirestore
        .collection("shippingAddresses")
        .doc(addressId)
        .delete();

      setAddresses(addresses.filter((addr) => addr.id !== addressId));
      setAddressUpdated(true);
    } catch (error) {
      console.error("Error deleting address: ", error);
    }
  };

  const handleSelectCountry = (country) => {
    setSelectedCountry(country);
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Your Addresses</h2>
      <Button
        variant="primary"
        className="mb-3"
        onClick={() => setEditingAddress({})}
      >
        Add New Address
      </Button>

      <CountryDropdown
        value={selectedCountry}
        onChange={handleSelectCountry}
        className="form-control mb-3"
      />

      {addresses.length === 0 ? (
        <p>No addresses found.</p>
      ) : (
        <ListGroup>
          {addresses.map((address) => (
            <ListGroup.Item key={address.id}>
              <div>
                {expandedAddresses.has(address.id) ? (
                  <>
                    <p>Address Type: {address.addressType}</p>
                    <p>Name: {address.fullName}</p>
                    <p>Street: {address.street}</p>
                    <p>City: {address.city}</p>
                    <p>Post Code: {address.zipCode}</p>
                    {/* <p>Country: {address.country}</p> */}
                    <p>Country: {selectedCountry}</p>
                    <p>
                      Coordinates: Lat {address.latitude}, Long{" "}
                      {address.longitude}
                    </p>
                  </>
                ) : (
                  <p>
                    {address.addressType}: {address.fullName}, {address.street},{" "}
                    {address.city}, {address.zipCode}
                  </p>
                )}
              </div>
              <div>
                <Button
                  variant="secondary"
                  className="mr-2"
                  onClick={() => toggleAddressView(address.id)}
                >
                  {expandedAddresses.has(address.id)
                    ? "Show Less"
                    : "Show More"}
                </Button>
                <Button
                  variant="warning"
                  className="mr-2"
                  onClick={() => handleEdit(address)}
                >
                  Edit
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(address.id)}
                >
                  Delete
                </Button>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}

      {/* AddEditAddressForm component */}
      {editingAddress && (
        <AddEditAddressForm
          address={editingAddress}
          onSave={(updatedAddress) => {
            const isNewAddress = !editingAddress.id;
            if (isNewAddress) {
              handleAddShippingAddress({
                ...updatedAddress,
                country: selectedCountry,
              });
            } else {
              handleEditShippingAddress(editingAddress.id, updatedAddress);
            }
            setEditingAddress(null);
          }}
          onCancel={() => setEditingAddress(null)}
        />
      )}

      <Button variant="info" className="mt-3" onClick={navigateToSendings}>
        Back to Sendings
      </Button>
    </div>
  );
};

export default AddressManager;
