import React, { createContext, useState, useContext } from "react";

const AddressUpdateContext = createContext();

export const useAddressUpdate = () => useContext(AddressUpdateContext);

export const AddressUpdateProvider = ({ children }) => {
  const [addressUpdated, setAddressUpdated] = useState(false);

  return (
    <AddressUpdateContext.Provider
      value={{ addressUpdated, setAddressUpdated }}
    >
      {children}
    </AddressUpdateContext.Provider>
  );
};
