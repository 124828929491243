import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { projectFirestore } from "../../../firebase/config";

const DeliveryInstructions = ({ orderId }) => {
  const [instructions, setInstructions] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await projectFirestore.collection("deliveryorders").doc(orderId).update({
        deliveryInstructions: instructions,
      });

      alert("Delivery instructions updated successfully.");
      setInstructions("");
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="instructions-container">
      <h3 className="title">Delivery Instructions</h3>
      <Form onSubmit={handleSubmit} className="form">
        <Form.Control
          as="textarea"
          value={instructions}
          onChange={(e) => setInstructions(e.target.value)}
          placeholder="Enter special instructions here..."
          required
        />
        <Button type="submit" disabled={isSubmitting} className="submit-button">
          {isSubmitting ? "Updating..." : "Update Instructions"}
        </Button>
      </Form>
      {error && <Alert variant="danger">{error}</Alert>}
    </div>
  );
};

export default DeliveryInstructions;
