import React from "react";
import { Card } from "react-bootstrap";

const ViewPackageDetails = ({
  senderName,
  senderAddress,
  deliveryName,
  deliveryAddress,
  dimensions,
  weight,
  cost,
}) => {
  if (!senderName || !deliveryName) {
    return <p>Package details not available.</p>;
  }

  // Format package size
  const packageSize = `${dimensions.length} x ${dimensions.width} x ${dimensions.height}`;

  return (
    <Card>
      <Card.Body>
        <Card.Title>Package Details</Card.Title>
        <Card.Text>
          <strong>Sender:</strong> {senderName} <br />
          <strong>Sender Address:</strong> {senderAddress} <br />
          <strong>Recipient:</strong> {deliveryName} <br />
          <strong>Delivery Address:</strong> {deliveryAddress} <br />
          <strong>Package Size:</strong> {packageSize} <br />
          <strong>Weight:</strong> {weight} kg <br />
          <strong>Cost:</strong>{" "}
          {cost ? `${cost.toFixed(2)} Currency` : "Calculating..."} <br />
        </Card.Text>
        {/* Add more package details as needed */}
      </Card.Body>
    </Card>
  );
};

export default ViewPackageDetails;
