import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { projectAuth } from "../../firebase/config";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { BsSearch } from "react-icons/bs";
import { UserContext } from "../contexts/UserContext";

function AppHeader() {
  const { currentUser, setCurrentUser, loading } = useContext(UserContext);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(projectAuth);
      setCurrentUser(null);
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
  };

  return (
    <Navbar bg="light" expand="lg" className="mb-3">
      <Container>
        <Navbar.Brand as={Link} to="/">
          Swiftti
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/policies">
              Our Policy
            </Nav.Link>
            <Nav.Link as={Link} to="/contacts">
              Contact
            </Nav.Link>
            <Nav.Link as={Link} to="/faqs">
              FAQs
            </Nav.Link>
            <Nav.Link as={Link} to="/dashboard">
              Dashboard
            </Nav.Link>

            {/* Search Form */}
            <form onSubmit={handleSearchSubmit} className="d-flex">
              <FormControl
                type="text"
                placeholder="Search"
                className="mr-sm-2"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
              <Button variant="outline-success" type="submit">
                <BsSearch />
              </Button>
            </form>

            {/* Conditional Rendering for User Info, Logout, Login, Signup */}
            <div className="d-flex align-items-center">
              {!loading && currentUser ? (
                <Dropdown align="end">
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    {currentUser.displayName || "User"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Header>{currentUser.email}</Dropdown.Header>
                    <Dropdown.ItemText>
                      Role: {currentUser.role || "User"}
                    </Dropdown.ItemText>
                    <Dropdown.ItemText>
                      UserId: {currentUser.uid || "Unknown"}
                    </Dropdown.ItemText>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <>
                  <Nav.Link as={Link} to="/signup">
                    SignUp
                  </Nav.Link>
                  <Nav.Link as={Link} to="/login">
                    Login
                  </Nav.Link>
                </>
              )}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AppHeader;
