import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { projectFirestore } from "../../firebase/config";
import { collection, query, where, getDocs } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container, Button, Form } from "react-bootstrap";

function Sendings() {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const [pickupAddresses, setPickupAddresses] = useState([]);
  const [selectedPickupAddress, setSelectedPickupAddress] = useState(null);
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(null);
  const [selectionError, setSelectionError] = useState("");

  const navigateToAddressManager = () => {
    navigate("/address-manager");
  };

  const handleNextClick = () => {
    if (selectedPickupAddress && selectedDeliveryAddress) {
      navigate("/delivery-form", {
        state: {
          selectedPickupAddress,
          selectedDeliveryAddress,
        },
      });
    } else {
      setSelectionError("Please select both a pickup and delivery address.");
    }
  };

  const fetchAddresses = async () => {
    if (!currentUser || !currentUser.uid) return;

    const userId = currentUser.uid;
    const addressesRef = collection(projectFirestore, "shippingAddresses");
    const q = query(addressesRef, where("userId", "==", userId));

    try {
      const querySnapshot = await getDocs(q);
      let pickupAddrs = [];
      let deliveryAddrs = [];

      querySnapshot.forEach((doc) => {
        let addressData = doc.data();
        if (addressData.addressType === "Pickup") {
          pickupAddrs.push({ id: doc.id, ...addressData });
        } else if (addressData.addressType === "Delivery") {
          deliveryAddrs.push({ id: doc.id, ...addressData });
        }
      });

      setPickupAddresses(pickupAddrs);
      setDeliveryAddresses(deliveryAddrs);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  // useEffect(() => {
  //     fetchAddresses();
  // }, [currentUser.uid]);

  useEffect(() => {
    if (!currentUser || !currentUser.uid) return;

    fetchAddresses();
  }, [currentUser]);

  return (
    <Container className="mt-5">
      <div className="mb-4">
        <h2>Welcome to the Package Sending Service</h2>
        <p>
          Start by selecting a pickup and delivery address for your package.
        </p>
      </div>

      <Button
        onClick={navigateToAddressManager}
        variant="primary"
        className="mb-3"
      >
        Manage Addresses
      </Button>

      <div>
        <h3>Select Pickup Address</h3>
        <Form>
          <ul>
            {pickupAddresses.map((address) => (
              <li key={address.id}>
                <Form.Check
                  type="radio"
                  label={`${address.fullName} - ${address.street}, ${address.city}`}
                  checked={selectedPickupAddress?.id === address.id}
                  onChange={() => setSelectedPickupAddress(address)}
                />
              </li>
            ))}
          </ul>
        </Form>
      </div>

      <div className="mb-3">
        <h3>Select Delivery Address</h3>
        <Form>
          <ul>
            {deliveryAddresses.map((address) => (
              <li key={address.id}>
                <Form.Check
                  type="radio"
                  label={`${address.fullName} - ${address.street}, ${address.city}`}
                  checked={selectedDeliveryAddress?.id === address.id}
                  onChange={() => setSelectedDeliveryAddress(address)}
                />
              </li>
            ))}
          </ul>
        </Form>
      </div>

      {selectionError && <p className="text-danger">{selectionError}</p>}

      <Button
        variant="primary"
        onClick={handleNextClick}
        disabled={!selectedPickupAddress || !selectedDeliveryAddress}
      >
        Next
      </Button>

      <ToastContainer />
    </Container>
  );
}

export default Sendings;
