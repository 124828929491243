import { BrowserRouter, Routes, Route } from "react-router-dom";
import { UserProvider } from "./components/contexts/UserContext";
import { OrderProvider } from "./components/contexts/OrderContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AppHeader from "./components/pages/AppHeader";
import AppHero from "./components/pages/AppHero";
import AppAbout from "./components/pages/about";
import AppServices from "./components/pages/services";
import AppWorks from "./components/pages/works";
import AppTeams from "./components/pages/teams";
import Login from "./components/Accounts/Login/Login";
import Signup from "./components/Accounts/Signup/Signup";
import AppBlog from "./components/pages/blog";
import AppContact from "./components/pages/contact";
import AppFooter from "./components/pages/footer";
import ProfileCompletion from "./components/Accounts/Signup/ProfileCompletion";
import Policies from "./components/Policies/Policies";
import PolicyList from "./components/Policies/PolicyList";
import PolicyEditor from "./components/Policies/PolicyEditor";
import Contacts from "./components/Contacts/Contacts";
import Faqs from "./components/Faqs/Faqs";
import FaqsList from "./components/Faqs/FaqsList";
import FaqEditor from "./components/Faqs/FaqEditor";
import DeliveryHome from "./components/SwifttiDeliveries/DeliveryHome";
import Sendings from "./components/Sendings/Sendings";
import AddressManager from "./components/ShippingAddress/AddressManager";
import DeliveryForm from "./components/SwifttiDeliveries/Packages/DeliveryForm";
import DashboardLayout from "./components/Dashboard/DashboardLayout";
import DeliveryOrderSummary from "./components/Sendings/DeliveryOrderSummary";
import Receivals from "./components/SwifttiDeliveries/Receivals/Receivals";
import MyOrders from "./components/SwifttiDeliveries/MyOrders";
import DeliveryHelp from "./components/SwifttiDeliveries/DeliveryHelp";
import PartnerOnboardingForm from "./components/Admin/SwifttiDeliveries/PartnerOnboardingForm";

function App() {
  return (
    <UserProvider>
      <OrderProvider>
        <div className="App">
          <BrowserRouter>
            <header id="header">
              <AppHeader />
            </header>
            <Routes>
              <Route path="/" element={<AppHero />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/faqs" element={<Faqs />} />
              <Route path="/faqs-list" element={<FaqsList />} />
              <Route path="/create-faq" element={<FaqEditor />} />
              <Route path="/edit-faq/:faqId" element={<FaqEditor />} />
              <Route path="/teams" element={<AppTeams />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/sendings" element={<Sendings />} />
              <Route path="/contact" element={<AppContact />} />
              <Route path="/address-manager" element={<AddressManager />} />
              <Route
                path="/profile-completion"
                element={<ProfileCompletion />}
              />
              <Route path="/policies" element={<Policies />} />
              <Route path="/create-policy" element={<PolicyEditor />} />
              <Route path="/policy-list" element={<PolicyList />} />

              <Route path="/delivery-home" element={<DeliveryHome />} />
              <Route path="/delivery-form" element={<DeliveryForm />} />
              <Route path="/receivals" element={<Receivals />} />
              <Route path="/myorders" element={<MyOrders />} />
              <Route path="/deliveryhelp" element={<DeliveryHelp />} />
              <Route path="/dashboard" element={<DashboardLayout />} />
              <Route
                path="/delivery-order-summary"
                element={<DeliveryOrderSummary />}
              />
              <Route path="/partner-onboarding" element={<PartnerOnboardingForm />} />
            </Routes>
            <footer id="footer">
              <AppFooter />
            </footer>
          </BrowserRouter>
        </div>
      </OrderProvider>
    </UserProvider>
  );
}

export default App;
