import { initializeApp } from "firebase/app";
import { getFirestore, serverTimestamp } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAEmHYOendJSsRWzl9bSSSPZVsqr9nM5bw",
  authDomain: "swiftti.firebaseapp.com",
  projectId: "swiftti",
  storageBucket: "swiftti.appspot.com",
  messagingSenderId: "979807755188",
  appId: "1:979807755188:web:337cc6708125cdc529f0e1",
  measurementId: "G-0NZ26V0W6E",
};

const app = initializeApp(firebaseConfig);

const projectAuth = getAuth(app);
const projectFirestore = getFirestore(app);
const projectStorage = getStorage(app);
const googleProvider = new GoogleAuthProvider();
const projectFunctions = getFunctions(app);
// const timestamp = firebase.firestore.FieldValue.serverTimestamp;

// export const { serverTimestamp } = firebase.firestore.FieldValue;
// export const FieldValue = firebase.firestore.FieldValue;

export {
  projectAuth,
  projectFirestore,
  projectStorage,
  googleProvider,
  projectFunctions,
  serverTimestamp,
};