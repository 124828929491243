import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";


var heroData = [
  {
    id: 1,
    image: require("../../assets/images/img-hero1.jpg"),
    title: "Industrial, Commercial and Retail Delivery Services",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab suscipit dicta nulla. Consequuntur obcaecati officiis, labore doloribus non tempore impedit consequatur ab dolor. Explicabo quam repellendus vero omnis, nisi odio!",
    route: "/delivery-home", // internal route path
    buttonText: "Explore Delivery Services", // Button text for this slide
  },
  {
    id: 2,
    image: require("../../assets/images/img-hero2.jpg"),
    title: "Pickup and DropOff Services Nationwide",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab suscipit dicta nulla. Consequuntur obcaecati officiis, labore doloribus non tempore impedit consequatur ab dolor. Explicabo quam repellendus vero omnis, nisi odio!",
    route: "/pickup-services", // internal route path
    buttonText: "View Your Pickup & DropOff Options", // Button text for this slide
  },
  {
    id: 3,
    image: require("../../assets/images/img-hero3.jpg"),
    title: "Sign Up as Delivery Partner and we forward you Delivery Orders",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab suscipit dicta nulla. Consequuntur obcaecati officiis, labore doloribus non tempore impedit consequatur ab dolor. Explicabo quam repellendus vero omnis, nisi odio!",
    route: "/partner-signup", // internal route path
    buttonText: "Join as a Partner", // Button text for this slide
  },
];

function AppHero() {
  return (
    <section id="home" className="hero-block">
       <Carousel>
          {
            heroData.map(hero => {
              return (
                <Carousel.Item key={hero.id}>
                  <img
                    className="d-block w-100"
                    src={hero.image}
                    alt={"slide " + hero.id}
                  />
                  <Carousel.Caption>
                    <h2>{hero.title}</h2>
                    <p>{hero.description}</p>
                    <Link className="btn btn-primary" to={hero.route}>
                      {hero.buttonText} <i className="fas fa-chevron-right"></i>
                    </Link>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })
          }
      </Carousel>
    </section>
  );
}

export default AppHero;