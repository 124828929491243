import React, { useState, useEffect } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { collection, getDocs, where, query, doc, deleteDoc } from "firebase/firestore";
import { projectFirestore } from "../../../firebase/config";
import { useUser } from "../../contexts/UserContext";
import DeliveryPartnerOnboarding from "./DeliveryPartnerOnboarding";

const DeliveryPartnerAdminManagerList = () => {
  const [deliveryPartners, setDeliveryPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const { currentUser } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser || !currentUser.uid) return;

    const fetchDeliveryPartners = async () => {
      try {
        const deliveryPartnerRef = collection(
          projectFirestore,
          "deliveryPartner"
        );
        const deliveryPartnerQuery = query(
          deliveryPartnerRef,
          where("userId", "==", currentUser.uid)
        );

        const querySnapshot = await getDocs(deliveryPartnerQuery);
        const partners = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setDeliveryPartners(partners);
      } catch (error) {
        console.error("Error fetching delivery partners:", error);
      }
    };

    fetchDeliveryPartners();
  }, [currentUser]);

  const handleDelete = async (partnerId) => {
    await deleteDoc(doc(projectFirestore, "deliveryPartner", partnerId));
    setDeliveryPartners(
      deliveryPartners.filter((partner) => partner.id !== partnerId)
    );
  };

  const handleEdit = (partnerId) => {
    navigate(`/edit-delivery-partner/${partnerId}`);
  };

  // console.log("Selected Partner:", selectedPartner);


  return (
    <Container>
      <h2>Delivery Partners</h2>
      {deliveryPartners.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Partner ID</th>
              <th>Business Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {deliveryPartners.map((partner) => (
              <tr key={partner.id}>
                <td>{partner.id}</td>
                <td>{partner.fullBusinessName}</td>
                <td>
                  <Button
                    variant="primary"
                    onClick={() => handleEdit(partner.id)}
                  >
                    Edit
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No delivery partners found.</p>
      )}

      {selectedPartner && (
        <div>
          <p>Editing Partner:</p>
          <pre>{JSON.stringify(selectedPartner, null, 2)}</pre>
          <DeliveryPartnerOnboarding partner={selectedPartner} />
        </div>
      )}
    </Container>
  );
};

export default DeliveryPartnerAdminManagerList;
