export const manageCleaningServices = [
  "All Cleaning Services",
  "Add Cleaning Service",
  "Cleaning Admin Dashboard",
  "Cleaning Partner Registration",
  "Cleaning Partner Profile",
  "Book Now",

  // 'Invitation Out',
];

export const manageAdvertServices = [
  "Advert Category Manager",
  "Advert Forms Configurations Form",
  "Advert Forms Configurations List",
  "Advert Category Configuration Mapping Form",
  "Advert Category Configuration Mapping List",
  "Advert Type Prices Manager",
  "Post Adverts",
];

export const manageDeliveryServices = [
  "Delivery Transport Category Manager",
  "Delivery Category Manager",
  "Delivery Partner Admin Manager",
  "Delivery Partner Admin Manager List",
  "Delivery Partner Onboarding",
  "Delivery Home Page",
  "Category Activation List",
];

export const manageAdminServices = [
  "Admin Panel",
  "Admin Interface",
  "AllGhanaians Provided Services",
  "AllGhanaians Policy Editor",
  "AllGhanaians Policy List",
];
export const manageProposalFeatures = ["Add Proposal"];

export const managePropertuFeatures = [
  "Property Listings",
  "Property Search",
  "Property Details",
  "Property Comparison",
  "Mortgage Calculator",
  "Test Pro",
];

export const manageDeliveryFeatures = [
  "Package Tracking",
  "Delivery Notifications",
  "Delivery History",
  "Delivery Address Book",
  "Delivery Preferences",
];

// constants.js or a similar file where you define constants

export const manageDeliveryPartnerFeatures = [
  "Package Pickup",
  "Delivery Management",
  "Route Optimization",
  "Delivery History",
  "Earnings Report",
  "Feedback and Ratings",
  "Order Status Manager",
];

export const manageInAppMessaingFeatures = [
  "Contacts List",
  "Sent Invitations",
  "Received Invitations",
  "Conversations",
  "Message List",
  "Message Item",
  "Message Input",
  "Message Thread",
  "Chat",
  "ChatUsers List",
  "ChatScreen",
];

export const manageIndivPartnerBooksFeatures = [
  "Bookshelf",
  "Add Book",
  "Update Book",
  "Chapter Update",
  "Archive Book",
  "Delete Book",
  "Read Book",
  "Book categories",
  "Recommendation",
  "Bookmarks",
  "Refund & Cancellation",
];

export const paymentTermOptions = [
  { value: "advancePayment", label: "Advance Payment" },
  { value: "cashOnDelivery", label: "Cash on Delivery" },
  { value: "letterOfCredit", label: "Letter of Credit" },
  { value: "net30", label: "Net 30" },
  { value: "net60", label: "Net 60" },
];
