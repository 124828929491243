import { getFirestore } from "firebase/firestore";
import {
  haversineDistance,
  adjustDistance,
  calculateDistanceFee,
  calculateWeightCost,
  calculateSizeCost,
  calculateWeightSizeMultiplier,
  SERVICE_LEVEL_MULTIPLIER,
} from "./DeliveryCostHelpers";

// export const calculateDeliveryCost = async (
//   packageDetails,
//   serviceLevel,
//   pickupAddress,
//   deliveryAddress
// ) => {
//   const logCoords = (coords, type) => {
//     console.log(`${type} Coordinates:`, coords);
//     if (isNaN(coords.lat) || isNaN(coords.lng)) {
//       console.error(`${type} coordinates are invalid.`);
//     }
//   };

//   try {
//     const weight = parseFloat(packageDetails.weight);
//     const dimensions = {
//       length: parseFloat(packageDetails.dimensions.length),
//       width: parseFloat(packageDetails.dimensions.width),
//       height: parseFloat(packageDetails.dimensions.height),
//     };

//     if (isNaN(weight) || Object.values(dimensions).some(isNaN)) {
//       throw new Error("Invalid package details for delivery cost calculation.");
//     }

//     const pickupCoords = {
//       lat: parseFloat(pickupAddress.latitude),
//       lng: parseFloat(pickupAddress.longitude),
//     };
//     const deliveryCoords = {
//       lat: parseFloat(deliveryAddress.latitude),
//       lng: parseFloat(deliveryAddress.longitude),
//     };

//     logCoords(pickupCoords, "Pickup");
//     logCoords(deliveryCoords, "Delivery");

//     if (
//       Object.values(pickupCoords).some(isNaN) ||
//       Object.values(deliveryCoords).some(isNaN)
//     ) {
//       throw new Error("Invalid coordinates for delivery cost calculation.");
//     }

//     // Continue with calculations...
//   } catch (error) {
//     console.error("Error calculating delivery cost:", error);
//     return 0; // Consider using a different error handling strategy here
//   }
// };


export const calculateDeliveryCost = async (
  packageDetails,
  serviceLevel,
  pickupAddress,
  deliveryAddress
) => {
  try {
    const weight = parseFloat(packageDetails.weight);
    const dimensions = {
      length: parseFloat(packageDetails.dimensions.length),
      width: parseFloat(packageDetails.dimensions.width),
      height: parseFloat(packageDetails.dimensions.height),
    };

    if (isNaN(weight) || Object.values(dimensions).some(isNaN)) {
      console.error(
        "Invalid package details provided for delivery cost calculation."
      );
      return 0;
    }

    const pickupCoords = {
      lat: parseFloat(pickupAddress.latitude),
      lng: parseFloat(pickupAddress.longitude),
    };
    const deliveryCoords = {
      lat: parseFloat(deliveryAddress.latitude),
      lng: parseFloat(deliveryAddress.longitude),
    };

    const straightLineDistance = haversineDistance(
      pickupCoords,
      deliveryCoords
    );
    const adjustedDistance = adjustDistance(straightLineDistance);
    const distanceFee = calculateDistanceFee(adjustedDistance);
    const weightCost = calculateWeightCost(weight);
    const sizeCost = calculateSizeCost(dimensions);
    const weightSizeMultiplier = calculateWeightSizeMultiplier(
      weight,
      dimensions
    );

    // Debugging logs
    console.log(`Straight line distance: ${straightLineDistance} km`);
    console.log(`Adjusted distance: ${adjustedDistance} km`);
    console.log(`Distance Fee: ${distanceFee}`);
    console.log(`Weight Cost: ${weightCost}`);
    console.log(`Size Cost: ${sizeCost}`);
    console.log(`Weight-Size Multiplier: ${weightSizeMultiplier}`);

    const totalCost =
      (distanceFee + weightCost + sizeCost) *
      weightSizeMultiplier *
      SERVICE_LEVEL_MULTIPLIER[serviceLevel];

    console.log(`Total Delivery Cost Calculated: ${totalCost}`);
    return totalCost;
  } catch (error) {
    console.error("Error calculating delivery cost:", error);
    return 0;
  }
};
