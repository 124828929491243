import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import sendingImage from "../../assets/dispatch.png";
import receivalsImage from "../../assets/receipt2.png";
import ordersImage from "../../assets/myorders1.png";
import helpImage from "../../assets/help.png";

const DeliveryHome = () => {
  const [orderId, setOrderId] = useState("");
  const navigate = useNavigate();

  const handleTrack = (e) => {
    e.preventDefault();
    if (orderId) {
      navigate(`/tracking/${orderId}`);
    } else {
      console.log("Please enter a valid Order ID");
    }
  };

  return (
    <div className="container mt-5">
      <form onSubmit={handleTrack}>
        <h3>Track Your Orders</h3>
        <div className="input-group mb-3">
          <input
            className="form-control"
            id="tracking-input"
            name="tracking-id"
            type="text"
            placeholder="Enter your order ID"
            required
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
          />
          <button className="btn btn-primary" type="submit">
            Track
          </button>
        </div>
      </form>

      <div className="row mt-5">
        <div className="col-md-12">
          <div className="card">
            <div className="row no-gutters">
              <div className="col-md-3">
                <img src={sendingImage} className="card-img" alt="Sending" />
              </div>
              <div className="col-md-9">
                <div className="card-body d-flex flex-column justify-content-between h-100">
                  <div>
                    <h5 className="card-title text-right">Dispatch</h5>
                    <p className="card-text text-right">Send a shipment</p>
                  </div>
                  <div className="text-right">
                    <Link to={`/sendings`} className="btn btn-secondary">
                      Sendings
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Repeat the card structure for other items */}
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <div className="card">
            <div className="row no-gutters">
              <div className="col-md-3">
                <img
                  src={receivalsImage}
                  className="card-img"
                  alt="Receivals"
                />
              </div>
              <div className="col-md-9">
                <div className="card-body d-flex flex-column justify-content-between h-100">
                  <div>
                    <h5 className="card-title text-right">Receipt</h5>
                    <p className="card-text text-right">
                      Receive your shipment
                    </p>
                  </div>
                  <div className="text-right">
                    <Link to={`/receivals`} className="btn btn-secondary">
                      Receivals
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Repeat the card structure for other items */}
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <div className="card">
            <div className="row no-gutters">
              <div className="col-md-3">
                <img src={ordersImage} className="card-img" alt="My Orders" />
              </div>
              <div className="col-md-9">
                <div className="card-body d-flex flex-column justify-content-between h-100">
                  <div>
                    <h5 className="card-title text-right">My Orders</h5>
                    <p className="card-text text-right">Your Orders</p>
                  </div>
                  <div className="text-right">
                    <Link to={`/myorders`} className="btn btn-secondary">
                      Go to order
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Repeat the card structure for other items */}
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <div className="card">
            <div className="row no-gutters">
              <div className="col-md-3">
                <img src={helpImage} className="card-img" alt="Help" />
              </div>
              <div className="col-md-9">
                <div className="card-body d-flex flex-column justify-content-between h-100">
                  <div>
                    <h5 className="card-title text-right">Help</h5>
                    <p className="card-text text-right">Help and Assistance</p>
                  </div>
                  <div className="text-right">
                    <Link to={`/deliveryhelp`} className="btn btn-secondary">
                      Let's Help You
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Repeat the card structure for other items */}
      </div>
    </div>
  );
};

export default DeliveryHome;
