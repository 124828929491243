import React from "react";
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const Contacts = () => {
  return (
    <div className="mt-5">
      <section id="contact" className="block contact-block bg-light py-5">
        <Container>
          <div className="title-holder text-center mb-5">
            <h2>Contact Us</h2>
            <p className="subtitle">Get connected with us</p>
          </div>
          <Form className="contact-form">
            <Row className="mb-3">
              <Col sm={12} md={4}>
                <Form.Control
                  type="text"
                  placeholder="Enter your full name"
                  required
                />
              </Col>
              <Col sm={12} md={4}>
                <Form.Control
                  type="email"
                  placeholder="Enter your email address"
                  required
                />
              </Col>
              <Col sm={12} md={4}>
                <Form.Control
                  type="tel"
                  placeholder="Enter your contact number"
                  required
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col sm={12}>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Enter your message"
                  required
                />
              </Col>
            </Row>
            <div className="text-center">
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </Container>
        {/* Adjusted Google Map container */}
        <div
          className="google-map"
          style={{ width: "100%", height: "400px", marginTop: "20px" }}
        >
          <iframe
            title="Our Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3969.9886950406094!2d-0.07742612434466949!3d5.7147647942671975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf8177cfe3cde5%3A0xa79a3b219c28747!2sMontgomery%20Golf%20Resort%20%26%20Private%20Residences!5e0!3m2!1sen!2snl!4v1711781717585!5m2!1sen!2snl"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <Container fluid className="mt-5">
          <div className="contact-info text-center">
            <ul className="list-unstyled">
              <li>
                <FontAwesomeIcon icon={faEnvelope} /> info@allghanaians.com
              </li>
              <li>
                <FontAwesomeIcon icon={faPhone} /> +233 555592621
              </li>
              <li>
                <FontAwesomeIcon icon={faMapMarkerAlt} /> Montgomery Golf
                Estates, 11 Montgomery Drive, GK-0465-2907 Santoe, East Legon
                Hills, Accra, Ghana
              </li>
            </ul>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Contacts;
