import React, { createContext, useContext, useState, useEffect } from 'react';
import { projectAuth, projectFirestore } from '../../firebase/config';
import { onAuthStateChanged, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { jwtDecode } from 'jwt-decode'; // Import jwtDecode

export const UserContext = createContext({ 
  currentUser: null, 
  setCurrentUser: () => {}, 
  loading: true,
  isAdmin: false, // Add isAdmin to the context
});

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false); // Add isAdmin state
  const [role, setRole] = useState('');

  useEffect(() => {
    setPersistence(projectAuth, browserLocalPersistence)
      .then(() => {
        const unsubscribe = onAuthStateChanged(projectAuth, async (firebaseUser) => {
          if (firebaseUser) {
            const userRef = doc(projectFirestore, 'users', firebaseUser.uid);
            try {
              const userSnap = await getDoc(userRef);
              if (userSnap.exists()) {
                const userData = {
                  ...firebaseUser,
                  ...userSnap.data(),
                };
               // console.log("User logged in, setting currentUser before setCurrentUser(userData); :", userData);
                setCurrentUser(userData);
                setRole(userData.role || 'end-user'); // Set role, default to 'end-user'
                //console.log("Current user set after setCurrentUser(userData); :", userData);
                //console.log(`User role: ${userData.role}`);
                // console.log("isAdmin:", isAdmin);

                // Fetch ID token result and check for custom claims
                firebaseUser.getIdToken().then((idToken) => {
                  //console.log("ID Token:", idToken);

                  // Decode the token
                  const decodedToken = jwtDecode(idToken);
                  //console.log("Decoded Token:", decodedToken);

                  // You can now use decodedToken to check custom claims
                  if (decodedToken.admin === true) {
                  // console.log("User is an admin.");
                    setIsAdmin(true); // Update the isAdmin state
                  } else {
                    setIsAdmin(false); // Make sure isAdmin is false if the user is not an admin
                  }
                });

              } else {
                setCurrentUser(firebaseUser);
              }
            } catch (error) {
              console.error("Error fetching user details from Firestore:", error);
            }
            setLoading(false); // Set loading to false when user data is fetched
          } else {
            //console.log("User logged out, setting currentUser to null");
            setCurrentUser(null);
            setIsAdmin(false); // Reset isAdmin to false when there's no user
            setLoading(false); // Set loading to false when there's no user
          }
        });

        return () => {
          unsubscribe(); // Cleanup subscription on unmount
          setLoading(true); // Reset loading state when component unmounts
        };
      })
      .catch((error) => {
        console.error("Error setting session persistence:", error);
        setLoading(false); // Set loading to false if there's an error during session persistence setup
      });
  }, []);

  // This useEffect is specifically for logging the isAdmin state whenever it updates
  useEffect(() => {
    // console.log("isAdmin state updated to:", isAdmin);
  }, [isAdmin]);

  // Function to update user role based on Firestore data
  // const updateUserRole = async (userId) => {
  //   const userRef = doc(projectFirestore, 'users', userId);
  //   const userSnap = await getDoc(userRef);
  //   if (userSnap.exists()) {
  //     const userData = userSnap.data();
  //     setRole(userData.role); // Update role in context
  //     console.log(`Role updated to ${userData.role}`);
  //   } else {
  //     console.log("User document doesn't exist.");
  //   }
  // };

  // Define updateUserRole function inside your UserProvider
  // const updateUserRole = async (newRole) => {
  //   if (!currentUser) {
  //     console.error('No current user found');
  //     return;
  //   }
  //   const userRef = doc(projectFirestore, 'users', currentUser.uid);
  //   await updateDoc(userRef, { role: newRole });
    
  //   // Assuming you want to update the role in your context as well
  //   setRole(newRole);
  //   console.log(`Role updated to ${newRole} in Firestore and Context`);
  // };

  const updateUserRole = async (userId, newRole) => {
    const userRef = doc(projectFirestore, 'users', userId);
    await updateDoc(userRef, {
      role: newRole, // Ensure this is 'partner' or any appropriate role string
    });
    console.log(`Role updated to ${newRole} for user ${userId}`);
  };
  


  return (
    <UserContext.Provider value={{ currentUser, setCurrentUser, loading, isAdmin, role, setRole, updateUserRole }}>
      {children}
    </UserContext.Provider>
  );
};






