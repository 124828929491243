import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { projectFirestore } from "../../firebase/config";
import DOMPurify from "dompurify";

const SimpleAccordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="card">
      <div className="card-header">
        <button className="btn btn-link" onClick={() => setIsOpen(!isOpen)}>
          {title}
        </button>
      </div>
      {isOpen && (
        <div
          className="card-body"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
        />
      )}
    </div>
  );
};

const Policies = () => {
  const [policies, setPolicies] = useState([]);

  useEffect(() => {
    const fetchPolicies = async () => {
      const querySnapshot = await getDocs(
        collection(projectFirestore, "policies")
      );
      const fetchedPolicies = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPolicies(fetchedPolicies);
    };

    fetchPolicies();
  }, []);

  return (
    <div className="container mt-3  pt-3">
      <h2>Our Policies</h2>
      {policies.map((policy) => (
        <SimpleAccordion
          key={policy.id}
          title={policy.name}
          content={policy.description}
        />
      ))}
    </div>
  );
};

export default Policies;
