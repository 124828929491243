// DeliveryCostHelpers.js
// Define WEIGHT_LIMIT_GHANA as a constant
const WEIGHT_LIMIT_GHANA = 6;
const RATE_PER_KG_OVER_LIMIT = 0.3;

export const calculateWeightCost = (weight) => {
  return weight <= WEIGHT_LIMIT_GHANA
    ? 0
    : (weight - WEIGHT_LIMIT_GHANA) * RATE_PER_KG_OVER_LIMIT;
};

export const SERVICE_LEVEL_MULTIPLIER = {
  STANDARD: 1,
  EXPRESS: 1.7,
  SAME_DAY: 2.2,
};

export const haversineDistance = (coords1, coords2) => {
  const toRad = (x) => (x * Math.PI) / 180;
  const R = 6371; // Radius of the Earth in km

  const dLat = toRad(coords2.lat - coords1.lat);
  const dLon = toRad(coords2.lng - coords1.lng);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(coords1.lat)) *
      Math.cos(toRad(coords2.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
};

export const adjustDistance = (distance) => {
  const FACTOR = 1.2; // Adjusting factor for actual travel distance
  return distance * FACTOR;
};

export const calculateDistanceFee = (distance) => {
  const BASE_FEE_GHANA = 7.0;
  const DISTANCE_RATE_GHANA = 0.6;
  const BASE_RADIUS = 10; // Base radius in km

  return distance <= BASE_RADIUS
    ? BASE_FEE_GHANA
    : BASE_FEE_GHANA + (distance - BASE_RADIUS) * DISTANCE_RATE_GHANA;
};

//   export const calculateWeightCost = (weight) => {
//     const WEIGHT_LIMIT_GHANA = 6;
//     const RATE_PER_KG_OVER_LIMIT = 0.3;

//     return weight <= WEIGHT_LIMIT_GHANA ? 0 : (weight - WEIGHT_LIMIT_GHANA) * RATE_PER_KG_OVER_LIMIT;
//   };

export const calculateSizeCost = (dimensions) => {
  const SIZE_LIMIT_GHANA = 1.2; // Size limit in cubic meters
  const { length, width, height } = dimensions;
  const volume = length * width * height;

  return volume > SIZE_LIMIT_GHANA ? (volume - SIZE_LIMIT_GHANA) * 0.2 : 0;
};

export const calculateWeightSizeMultiplier = (weight, dimensions) => {
  const WEIGHT_SIZE_MULTIPLIER = {
    STANDARD: 1,
    OVERWEIGHT: 1.3,
    HEAVY: 1.6,
  };
  const SIZE_LIMIT_GHANA = 1.2;
  const volume = dimensions.length * dimensions.width * dimensions.height;

  if (weight > 10 || volume > SIZE_LIMIT_GHANA) {
    return WEIGHT_SIZE_MULTIPLIER.HEAVY;
  } else if (weight > WEIGHT_LIMIT_GHANA) {
    return WEIGHT_SIZE_MULTIPLIER.OVERWEIGHT;
  }
  return WEIGHT_SIZE_MULTIPLIER.STANDARD;
};
