// import React, { useState, useEffect } from "react";
// import { Form, Table } from "react-bootstrap";
// import { projectFirestore } from "../../../firebase/config";
// import {
//   collection,
//   getDocs,
//   updateDoc,
//   doc,
//   getDoc,
// } from "firebase/firestore";

// const CategoryActivationList = () => {
//   const [categories, setCategories] = useState([]);

//   useEffect(() => {
//     const fetchCategories = async () => {
//       try {
//         const querySnapshot = await getDocs(
//           collection(projectFirestore, "DeliveryCategories")
//         );
//         const categoriesData = querySnapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//           active: doc.data().active ?? false, // Ensure 'active' is always boolean
//           subCategories: (doc.data().subCategories || []).map(
//             (subCategory) => ({
//               ...subCategory,
//               active: subCategory.active ?? false, // Initialize 'active' for subCategories
//               attributes: subCategory.attributes || {}, // Ensure attributes remain a map
//             })
//           ),
//         }));
//         setCategories(categoriesData);
//       } catch (error) {
//         console.error("Error fetching categories:", error);
//       }
//     };

//     fetchCategories();
//   }, []);

//   const updateCategoryDocument = async (categoryId, newData) => {
//     const categoryRef = doc(projectFirestore, "DeliveryCategories", categoryId);
//     await updateDoc(categoryRef, newData);
//   };

//   const handleToggleCategory = async (categoryId, isChecked) => {
//     await updateCategoryDocument(categoryId, { active: isChecked });
//     setCategories((prevCategories) =>
//       prevCategories.map((category) =>
//         category.id === categoryId
//           ? { ...category, active: isChecked }
//           : category
//       )
//     );
//   };

//   const handleToggleSubCategory = async (
//     categoryId,
//     subCategoryId,
//     isChecked
//   ) => {
//     const categoryRef = doc(projectFirestore, "DeliveryCategories", categoryId);
//     const categorySnap = await getDoc(categoryRef);

//     if (categorySnap.exists()) {
//       const categoryData = categorySnap.data();
//       const updatedSubCategories = categoryData.subCategories.map(
//         (subCategory) =>
//           subCategory.id === subCategoryId
//             ? { ...subCategory, active: isChecked }
//             : subCategory
//       );

//       await updateCategoryDocument(categoryId, {
//         subCategories: updatedSubCategories,
//       });
//       setCategories((prevCategories) =>
//         prevCategories.map((category) =>
//           category.id === categoryId
//             ? {
//                 ...category,
//                 subCategories: updatedSubCategories,
//               }
//             : category
//         )
//       );
//     }
//   };



//   return (
//     <Table striped bordered hover>
//       <thead>
//         <tr>
//           <th>Category</th>
//           <th>Subcategory</th>
//           <th>Attribute</th>
//         </tr>
//       </thead>
//       <tbody>
//         {categories.map((category) => (
//           <React.Fragment key={category.id}>
//             <tr>
//               <td>
//                 <Form.Check
//                   type="checkbox"
//                   label={category.name}
//                   checked={category.active}
//                   onChange={(e) =>
//                     handleToggleCategory(category.id, e.target.checked)
//                   }
//                 />
//               </td>
//               <td></td>
//               <td></td>
//             </tr>
//             {category.subCategories.map((subCategory) => (
//               <React.Fragment key={subCategory.id}>
//                 <tr>
//                   <td></td>
//                   <td>
//                     <Form.Check
//                       type="checkbox"
//                       label={subCategory.name}
//                       checked={subCategory.active}
//                       onChange={(e) =>
//                         handleToggleSubCategory(
//                           category.id,
//                           subCategory.id,
//                           e.target.checked
//                         )
//                       }
//                     />
//                   </td>
//                   <td></td>
//                 </tr>
//                 {subCategory.attributes &&
//                   Object.entries(subCategory.attributes).map(
//                     ([attributeKey, attribute]) => (
//                       <tr key={attributeKey}>
//                         <td></td>
//                         <td></td>
//                         <td>
//                           {/* <Form.Check
//                             type="checkbox"
//                             label={attributeKey}
//                             checked={attribute.active}
//                             onChange={(e) =>
//                               handleToggleAttribute(
//                                 category.id,
//                                 subCategory.id,
//                                 attributeKey,
//                                 e.target.checked
//                               )
//                             }
//                           /> */}
//                         </td>
//                       </tr>
//                     )
//                   )}
//               </React.Fragment>
//             ))}
//           </React.Fragment>
//         ))}
//       </tbody>
//     </Table>
//   );
// };

// export default CategoryActivationList;


// import React, { useState, useEffect } from "react";
// import { Form, Table, Container } from "react-bootstrap";
// import { projectFirestore } from "../../../firebase/config";
// import {
//   collection,
//   getDocs,
//   updateDoc,
//   doc,
//   getDoc,
// } from "firebase/firestore";

// const CategoryActivationList = () => {
//   const [categories, setCategories] = useState([]);

//   useEffect(() => {
//     const fetchCategories = async () => {
//       try {
//         const querySnapshot = await getDocs(
//           collection(projectFirestore, "DeliveryCategories")
//         );
//         const categoriesData = querySnapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//           active: doc.data().active ?? false, // Ensure 'active' is always boolean
//           subCategories: (doc.data().subCategories || []).map(
//             (subCategory) => ({
//               ...subCategory,
//               active: subCategory.active ?? false, // Initialize 'active' for subCategories
//               attributes: subCategory.attributes || {}, // Ensure attributes remain a map
//             })
//           ),
//         }));
//         setCategories(categoriesData);
//       } catch (error) {
//         console.error("Error fetching categories:", error);
//       }
//     };

//     fetchCategories();
//   }, []);

//   const updateCategoryDocument = async (categoryId, newData) => {
//     const categoryRef = doc(projectFirestore, "DeliveryCategories", categoryId);
//     await updateDoc(categoryRef, newData);
//   };

//   const handleToggleCategory = async (categoryId, isChecked) => {
//     await updateCategoryDocument(categoryId, { active: isChecked });
//     setCategories((prevCategories) =>
//       prevCategories.map((category) =>
//         category.id === categoryId
//           ? { ...category, active: isChecked }
//           : category
//       )
//     );
//   };

//   const handleToggleSubCategory = async (
//     categoryId,
//     subCategoryId,
//     isChecked
//   ) => {
//     const categoryRef = doc(projectFirestore, "DeliveryCategories", categoryId);
//     const categorySnap = await getDoc(categoryRef);

//     if (categorySnap.exists()) {
//       const categoryData = categorySnap.data();
//       const updatedSubCategories = categoryData.subCategories.map(
//         (subCategory) =>
//           subCategory.id === subCategoryId
//             ? { ...subCategory, active: isChecked }
//             : subCategory
//       );

//       await updateCategoryDocument(categoryId, {
//         subCategories: updatedSubCategories,
//       });
//       setCategories((prevCategories) =>
//         prevCategories.map((category) =>
//           category.id === categoryId
//             ? {
//                 ...category,
//                 subCategories: updatedSubCategories,
//               }
//             : category
//         )
//       );
//     }
//   };

//   return (
//     <Container fluid className="pt-5">
//       <Table striped bordered hover>
//         <thead>
//           <tr>
//             <th>Category</th>
//             <th>Subcategory</th>
//             <th>Attributes</th>
//           </tr>
//         </thead>
//         <tbody>
//           {categories.map((category) => (
//             <React.Fragment key={category.id}>
//               <tr>
//                 <td>
//                   <Form.Check
//                     type="checkbox"
//                     label={category.name}
//                     checked={category.active}
//                     onChange={(e) =>
//                       handleToggleCategory(category.id, e.target.checked)
//                     }
//                   />
//                 </td>
//                 <td colSpan="2"></td>
//               </tr>
//               {category.subCategories.map((subCategory) => (
//                 <React.Fragment key={subCategory.id}>
//                   <tr>
//                     <td></td>
//                     <td>
//                       <Form.Check
//                         type="checkbox"
//                         label={subCategory.name}
//                         checked={subCategory.active}
//                         onChange={(e) =>
//                           handleToggleSubCategory(
//                             category.id,
//                             subCategory.id,
//                             e.target.checked
//                           )
//                         }
//                       />
//                     </td>
//                     <td>
//                       {Object.entries(subCategory.attributes).map(
//                         ([key, value]) => (
//                           <div key={key}>
//                             <strong>{key}:</strong> {value}
//                           </div>
//                         )
//                       )}
//                     </td>
//                   </tr>
//                 </React.Fragment>
//               ))}
//             </React.Fragment>
//           ))}
//         </tbody>
//       </Table>
//     </Container>
//   );
// };

// export default CategoryActivationList;


import React, { useState, useEffect } from "react";
import { Form, Table, Container } from "react-bootstrap";
import { projectFirestore } from "../../../firebase/config";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";

const CategoryActivationList = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(projectFirestore, "DeliveryCategories")
        );
        const categoriesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          active: doc.data().active ?? false,
          attributes: doc.data().attributes || {},
          subCategories: (doc.data().subCategories || []).map(
            (subCategory) => ({
              ...subCategory,
              active: subCategory.active ?? false,
              attributes: subCategory.attributes || {},
            })
          ),
        }));
        setCategories(categoriesData);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const updateCategoryDocument = async (categoryId, newData) => {
    const categoryRef = doc(projectFirestore, "DeliveryCategories", categoryId);
    await updateDoc(categoryRef, newData);
  };

  const handleToggleCategory = async (categoryId, isChecked) => {
    await updateCategoryDocument(categoryId, { active: isChecked });
    setCategories((prevCategories) =>
      prevCategories.map((category) =>
        category.id === categoryId
          ? { ...category, active: isChecked }
          : category
      )
    );
  };

  const handleToggleSubCategory = async (
    categoryId,
    subCategoryId,
    isChecked
  ) => {
    const categoryRef = doc(projectFirestore, "DeliveryCategories", categoryId);
    const categorySnap = await getDoc(categoryRef);

    if (categorySnap.exists()) {
      const categoryData = categorySnap.data();
      const updatedSubCategories = categoryData.subCategories.map(
        (subCategory) =>
          subCategory.id === subCategoryId
            ? { ...subCategory, active: isChecked }
            : subCategory
      );

      await updateCategoryDocument(categoryId, {
        subCategories: updatedSubCategories,
      });
      setCategories((prevCategories) =>
        prevCategories.map((category) =>
          category.id === categoryId
            ? {
                ...category,
                subCategories: updatedSubCategories,
              }
            : category
        )
      );
    }
  };

  return (
    <Container fluid className="pt-5">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Category</th>
            <th>Subcategory</th>
            <th>Attributes</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <React.Fragment key={category.id}>
              <tr>
                <td>
                  <Form.Check
                    type="checkbox"
                    label={category.name}
                    checked={category.active}
                    onChange={(e) =>
                      handleToggleCategory(category.id, e.target.checked)
                    }
                  />
                </td>
                <td colSpan="2">
                  {Object.entries(category.attributes).map(([key, value]) => (
                    <div key={key}>
                      <strong>{key}:</strong> {value}
                    </div>
                  ))}
                </td>
              </tr>
              {category.subCategories.map((subCategory) => (
                <React.Fragment key={subCategory.id}>
                  <tr>
                    <td></td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        label={subCategory.name}
                        checked={subCategory.active}
                        onChange={(e) =>
                          handleToggleSubCategory(
                            category.id,
                            subCategory.id,
                            e.target.checked
                          )
                        }
                      />
                    </td>
                    <td>
                      {Object.entries(subCategory.attributes).map(
                        ([key, value]) => (
                          <div key={key}>
                            <strong>{key}:</strong> {value}
                          </div>
                        )
                      )}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default CategoryActivationList;
