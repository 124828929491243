// import React from "react";
// import { Form } from "react-bootstrap";

// const TimeSlotSelector = ({ day, availability, setAvailability }) => {
//   const times = Array.from({ length: 24 }, (_, i) => `${i}:00`); // Generating hours for the day

//   const handleTimeChange = (timeType, value) => {
//     setAvailability((prev) => ({
//       ...prev,
//       [day]: {
//         ...prev[day],
//         [timeType]: value,
//       },
//     }));
//   };

//   return (
//     <>
//       <Form.Select
//         value={availability[day].start}
//         onChange={(e) => handleTimeChange("start", e.target.value)}
//       >
//         <option value="">Start Time</option>
//         {times.map((time) => (
//           <option key={time} value={time}>
//             {time}
//           </option>
//         ))}
//       </Form.Select>
//       <Form.Select
//         value={availability[day].end}
//         onChange={(e) => handleTimeChange("end", e.target.value)}
//       >
//         <option value="">End Time</option>
//         {times.map((time) => (
//           <option key={time} value={time}>
//             {time}
//           </option>
//         ))}
//       </Form.Select>
//     </>
//   );
// };

// export default TimeSlotSelector;



import React from "react";
import { Form } from "react-bootstrap";

const TimeSlotSelector = ({ day, availability, setAvailability }) => {
  const times = Array.from({ length: 24 }, (_, i) => `${i}:00`); // Generating hours for each day

  const handleTimeChange = (timeType, value) => {
    setAvailability({
      ...availability,
      [day]: {
        ...availability[day],
        [timeType]: value,
      },
    });
  };

  return (
    <>
      <Form.Label>{day}</Form.Label>
      <Form.Select
        value={availability[day].start}
        onChange={(e) => handleTimeChange("start", e.target.value)}
      >
        <option value="">Start Time</option>
        {times.map((time) => (
          <option key={`${day}-start-${time}`} value={time}>
            {time}
          </option>
        ))}
      </Form.Select>
      <Form.Select
        value={availability[day].end}
        onChange={(e) => handleTimeChange("end", e.target.value)}
      >
        <option value="">End Time</option>
        {times.map((time) => (
          <option key={`${day}-end-${time}`} value={time}>
            {time}
          </option>
        ))}
      </Form.Select>
    </>
  );
};

export default TimeSlotSelector;
