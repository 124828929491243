import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { projectFirestore } from "../../firebase/config";
import { getDoc, doc } from "firebase/firestore";
import { Container, Row, Col, Card } from "react-bootstrap";
import FlutterwavePayments from "../Payments/FlutterwavePayments";

function DeliveryOrderSummary() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const orderId = location.state?.orderData?.orderId;
  const [orderData, setOrderData] = useState(null);
  const {
    selectedPickupAddress,
    selectedDeliveryAddress,
    packageDetails,
    deliveryCost,
  } = orderData || {};
  const [calculatedPrice, setCalculatedPrice] = useState(null);
  const [displayAmount, setDisplayAmount] = useState("0.00");

  // Define missing variables or functions
  const selectedServiceDetails = {}; // Define the selectedServiceDetails object
  const formData = {}; // Define the formData object
  const setBookingConfirmation = () => {}; // Define the setBookingConfirmation function
  const resetForm = () => {}; // Define the resetForm function

  useEffect(() => {
    const fetchOrderData = async () => {
      setIsLoading(true);
      setError(null);
      if (!orderId) {
        setError("Order ID not found.");
        setIsLoading(false);
        return;
      }

      try {
        const docSnap = await getDoc(
          doc(projectFirestore, "deliveryorders", orderId)
        );
        if (docSnap.exists()) {
          setOrderData({ orderId, ...docSnap.data() });
        } else {
          setError("No such order!");
        }
      } catch (error) {
        setError(`Error fetching order: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrderData();
  }, [orderId]);

  // Moved serviceDate declaration below packageDetails destructuring assignment
  const serviceDate = packageDetails?.serviceDate || "Not available";

  function formatDate(dateString) {
    if (!dateString) return "Date not set";
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? "Invalid Date" : date.toLocaleDateString();
  }

  const handlePaymentSuccess = async (response) => {
    if (response.redirectstatus === undefined) {
      delete response.redirectstatus;
    }

    try {
      await projectFirestore.collection("deliveryorders").doc(orderId).update({
        isPaymentCompleted: true,
        paymentStatus: "paid",
      });
      console.log(`Payment status updated for order ID: ${orderId}`);
      navigate("/sendings");
      setCalculatedPrice(null);
      setDisplayAmount("0.00");
    } catch (error) {
      console.error("Error updating payment status:", error);
    }
  };

  const formatAddress = (address) => {
    return address
      ? `${address.street}, ${address.city}`
      : "No address selected";
  };

  if (isLoading) {
    return <div>Loading order data...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!orderData) {
    return <div>Order data not found.</div>;
  }

  return (
    <Container className="pt-5">
      <h2 className="mt-4 mb-4">Order Summary</h2>
      <Card>
        <Card.Body>
          <p>Order ID: {orderData.orderId || "Not available"}</p>

          <h5 className="mt-3">Addresses</h5>
          <Row>
            <Col>
              <p>
                <strong>Pickup Address:</strong>{" "}
                {formatAddress(selectedPickupAddress)}
              </p>
              <p>
                <strong>Delivery Address:</strong>{" "}
                {formatAddress(selectedDeliveryAddress)}
              </p>
            </Col>
          </Row>

          <h5 className="mt-3">Package Details</h5>
          <Row>
            <Col>
              <p>
                <strong>Package Type:</strong> {packageDetails.selectedCategory}
              </p>
              <p>
                <strong>Weight:</strong> {packageDetails.weight}
              </p>
              <p>
                <strong>Dimensions:</strong>{" "}
                {`${packageDetails.dimensions.length} x ${packageDetails.dimensions.width} x ${packageDetails.dimensions.height}`}
              </p>
              <p>
                <strong>Description:</strong> {packageDetails.description}
              </p>
            </Col>
          </Row>

          <h5 className="mt-3">Dates</h5>
          <Row>
            <Col>
              <p>
                <strong>Pickup Date:</strong>{" "}
                {formatDate(packageDetails?.pickupDate)}
              </p>
              <p>
                <strong>Delivery Date:</strong>{" "}
                {formatDate(packageDetails?.deliveryDate)}
              </p>
              {/* Moved serviceDate render here */}
              <p>
                <strong>Service Date:</strong> {serviceDate}
              </p>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <p>
                <strong>Calculated Delivery Cost:</strong>{" "}
                {deliveryCost.toFixed(2)}
              </p>
            </Col>
          </Row>

          {deliveryCost !== undefined && (
            <FlutterwavePayments
              //calculatedPrice={deliveryCost} // Make sure to pass calculatedPrice instead of amount
              calculatedPrice={calculatedPrice || deliveryCost} // Make sure to pass calculatedPrice instead of amount
              selectedServiceDetails={selectedServiceDetails}
              formData={formData}
              setBookingConfirmation={setBookingConfirmation}
              resetForm={resetForm}
              onPaymentSuccess={handlePaymentSuccess} // Assuming this prop is also needed
            />
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}

export default DeliveryOrderSummary;
